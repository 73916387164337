import {Outlet, useNavigate} from 'react-router-dom'
import {NeoProvider} from '@kensho/neo'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

import ErrorBoundary from '../components/ErrorBoundary'
import UserProvider from '../providers/UserProvider'
import CookieConsentPopup from '../components/CookieBanner/CookieConsentPopup'
import SiteAnalyticsProvider from '../providers/SiteAnalyticsProvider'
import queryClient from '../app/queryClient'

export default function RootPage(): React.ReactNode {
  const navigate = useNavigate()
  return (
    <QueryClientProvider client={queryClient}>
      <NeoProvider navigate={navigate}>
        <ReactQueryDevtools buttonPosition="bottom-left" />
        <ErrorBoundary>
          <SiteAnalyticsProvider>
            <CookieConsentPopup />
            <UserProvider>
              <Outlet />
            </UserProvider>
          </SiteAnalyticsProvider>
        </ErrorBoundary>
      </NeoProvider>
    </QueryClientProvider>
  )
}
