import {useToaster} from '@kensho/neo'
import {useDebouncedValue} from '@kensho/tacklebox'
import {useEffect, useReducer} from 'react'

import useDeleteTranscript from '../../../api/useDeleteTranscript'
import assertNever from '../../../utils/assertNever'
import {Mode, TranscriptMetadata} from '../../../types/types'
import useTranscriptsMetadataQuery from '../../../hooks/useTranscriptsMetadataQuery'

import ActionBar from './ActionBar'
import PaginationBar from './PaginationBar'
import {TRANSCRIPTS_PER_PAGE} from './shared'
import TranscriptMetadataTable from './TranscriptMetadataTable'
import {OptionsAction, OptionsState} from './types'

function optionsReducer(state: OptionsState, action: OptionsAction): OptionsState {
  switch (action.type) {
    case 'search':
      return {...state, search: action.payload, page: 0}
    case 'sort':
      return {...state, sort: action.payload.sort, direction: action.payload.direction, page: 0}
    case 'page':
      return {...state, page: action.payload}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

interface HistoricalTranscriptsTableProps {
  onNewTranscriptConfig: (configMode: Mode) => void
}
export default function HistoricalTranscriptsTable({
  onNewTranscriptConfig,
}: HistoricalTranscriptsTableProps): React.ReactNode {
  const [{page, search, direction, sort}, optionsDispatch] = useReducer(optionsReducer, {
    search: '',
    sort: 'updated_on',
    direction: 'descending',
    page: 0,
  })
  const debouncedSearch = useDebouncedValue(search, 200)

  const {data, status, refetch} = useTranscriptsMetadataQuery({
    page: `${page}`,
    page_size: `${TRANSCRIPTS_PER_PAGE}`,
    name: debouncedSearch,
    sort,
    direction,
  })
  const transcriptMetadatas = data?.json
  const contentRange = data?.contentRange

  const deleteTranscriptMutation = useDeleteTranscript()
  const toaster = useToaster()

  useEffect(() => {
    if (status === 'error') {
      // We have requested an invalid page, so go back to the first page which is always safe
      if (
        transcriptMetadatas &&
        'msg_code' in transcriptMetadatas &&
        transcriptMetadatas.msg_code === 'S2T-TR-0001'
      ) {
        optionsDispatch({type: 'page', payload: 0})
        return
      }
      throw new Error(
        `HistoricalTranscriptsTable, error was: ${JSON.stringify(transcriptMetadatas)}`,
      )
    }
  }, [transcriptMetadatas, status])

  async function deleteTranscript(transcript: TranscriptMetadata): Promise<void> {
    try {
      const resp = await deleteTranscriptMutation(transcript.id)
      if (!resp.ok) throw new Error()
      if (contentRange) {
        const {startIndex, endIndex} = contentRange
        // if the current page is empty after deleting the transcript, go to the previous page if it exists
        if (startIndex === endIndex && page > 0) {
          optionsDispatch({type: 'page', payload: page - 1})
        } else {
          // TODO: Refactor the delete logic to use react query mutations
          await refetch()
        }
      }
    } catch (err) {
      toaster.show({
        label: `Error deleting transcript ${transcript.name}`,
        intent: 'danger',
      })
    }
  }

  const transcripts =
    transcriptMetadatas && !('msg_code' in transcriptMetadatas) ? transcriptMetadatas : null

  const isPending = status === 'pending'
  return (
    <div className="mt-12">
      <div className="flex max-h-[calc(100vh-160px)] w-[768px] max-w-[100vw] flex-col items-center">
        <div className="mb-11 w-full">
          <ActionBar
            search={search}
            optionsDispatch={optionsDispatch}
            onNewTranscriptConfig={onNewTranscriptConfig}
          />
        </div>
        <TranscriptMetadataTable
          deleteTranscript={deleteTranscript}
          isPending={isPending}
          transcripts={transcripts}
          sort={sort}
          direction={direction}
          optionsDispatch={optionsDispatch}
          refreshData={() => refetch()}
        />
        {!isPending && transcripts && transcripts.length === 0 && (
          <div className="p-4 text-center">
            {search ? (
              <>No results matched your search</>
            ) : (
              <>{`You don't have transcripts at the moment.`}</>
            )}
          </div>
        )}

        <PaginationBar
          page={page}
          optionsDispatch={optionsDispatch}
          isPending={isPending}
          contentRange={contentRange}
        />
      </div>
    </div>
  )
}
