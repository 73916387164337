import {Button, Tooltip} from '@kensho/neo'

import Skeleton from '../../../components/Skeleton'

import {TRANSCRIPTS_PER_PAGE} from './shared'
import {OptionsAction, TranscriptMetadataContentRange} from './types'

interface PaginationBarProps {
  isPending: boolean
  page: number
  contentRange?: TranscriptMetadataContentRange
  optionsDispatch: React.Dispatch<OptionsAction>
}

export default function PaginationBar(props: PaginationBarProps): React.ReactNode {
  const {isPending, page, optionsDispatch, contentRange} = props
  if (!isPending && contentRange?.totalTranscripts === 0) return null
  return (
    <div className="mx-auto mt-6 flex items-center gap-4">
      {(() => {
        if (!contentRange) {
          return (
            <>
              <Skeleton width={16} />
              <Skeleton width={16} />
              <Skeleton width={240} />
              <Skeleton width={16} />
              <Skeleton width={16} />
            </>
          )
        }
        const {endIndex, startIndex, totalTranscripts, hasNextPage} = contentRange
        return (
          <>
            <Tooltip content="Go to first page">
              <Button
                aria-label="Go to first page"
                disabled={isPending || page === 0}
                icon="ChevronDoubleLeftIcon"
                minimal
                onClick={() => optionsDispatch({type: 'page', payload: 0})}
                size="small"
              />
            </Tooltip>
            <Tooltip content="Go to previous page">
              <Button
                aria-label="Go to previous page"
                disabled={isPending || page === 0}
                icon="ChevronLeftIcon"
                minimal
                onClick={() => optionsDispatch({type: 'page', payload: page - 1})}
                size="small"
              />
            </Tooltip>
            <div>
              Displaying{' '}
              {isPending ? (
                <Skeleton className="m-0" width={40} />
              ) : (
                `${startIndex + 1} - ${endIndex + 1}`
              )}{' '}
              of {totalTranscripts} transcripts
            </div>
            <Tooltip content="Go to next page">
              <Button
                aria-label="Go to next page"
                disabled={isPending || !hasNextPage}
                icon="ChevronRightIcon"
                minimal
                onClick={() => optionsDispatch({type: 'page', payload: page + 1})}
                size="small"
              />
            </Tooltip>
            <Tooltip content="Go to last page">
              <Button
                aria-label="Go to last page"
                disabled={isPending || totalTranscripts === endIndex + 1}
                icon="ChevronDoubleRightIcon"
                minimal
                onClick={() =>
                  optionsDispatch({
                    type: 'page',
                    payload: Math.floor(totalTranscripts / TRANSCRIPTS_PER_PAGE),
                  })
                }
                size="small"
              />
            </Tooltip>
          </>
        )
      })()}
    </div>
  )
}
