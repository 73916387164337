import clsx from 'clsx'
import {useId} from 'react'
import {uniq} from 'lodash-es'

import prettyFileSize from '../utils/prettyFileSize'
import {VALID_AUDIO_FILE_EXTS, VALID_VIDEO_FILE_EXTS} from '../core/transcription/constants'

const VALID_FILE_EXTS = uniq([...VALID_AUDIO_FILE_EXTS, ...VALID_VIDEO_FILE_EXTS]).join(', ')

export default function MediaFileInput({
  label,
  description,
  errorText,
  accept = VALID_FILE_EXTS,
  value,
  disabled = false,
  isInvalid = false,
  onChange,
}: {
  label?: string
  description?: string
  errorText?: string
  accept?: string
  value?: File
  disabled?: boolean
  isInvalid?: boolean
  onChange?: (file: File | undefined) => void
}): React.ReactNode {
  const id = useId()
  const descriptionId = `${id}-description`

  return (
    <div>
      <label htmlFor={id}>
        {label && (
          <p
            className={clsx(
              'block pb-2 text-sm font-medium leading-6',
              disabled ? 'text-gray-500' : 'text-gray-900',
            )}
          >
            {label}
          </p>
        )}
        <input
          id={id}
          type="file"
          accept={accept}
          aria-describedby={description ? descriptionId : undefined}
          className="peer/input sr-only"
          disabled={disabled}
          onChange={(event) => {
            onChange?.(event.target.files?.[0])
          }}
        />
        <div
          className={clsx(
            'block h-14 w-full cursor-pointer rounded-lg border-0 p-2 pr-3 text-gray-900 outline-none ring-inset transition-all placeholder:text-gray-400 peer-focus-visible/input:ring-2 peer-focus-visible/input:ring-cyan-600 peer-disabled/input:bg-gray-200 peer-disabled/input:text-gray-500',
            isInvalid
              ? 'ring-2 ring-red-600'
              : 'ring-1 ring-gray-300 hover:ring-gray-500 disabled:ring-0',
          )}
        >
          <div className="flex h-full items-center">
            <div className="ml-2 mr-4 text-gray-400">
              {/* TODO: switch to @kensho/icons#IconDocument once package is distributed with latest hero icons */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
              >
                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
              </svg>
            </div>

            <div className="overflow-hidden text-ellipsis text-sm">
              {value === undefined ? (
                <p className="text-gray-400">Choose a file to upload</p>
              ) : (
                <>
                  <p>{value.name}</p>
                  <p>{prettyFileSize(value.size)}</p>
                </>
              )}
            </div>
          </div>
        </div>
        {errorText && isInvalid && (
          <div className="mt-2 text-sm text-red-600" id={descriptionId}>
            {errorText}
          </div>
        )}
        {description && !isInvalid && (
          <div className="mt-2 text-sm text-gray-500" id={descriptionId}>
            {description}
          </div>
        )}
      </label>
    </div>
  )
}
