import {applyPatches} from 'immer'
import {useMemo, useReducer} from 'react'
import {takeRight} from 'lodash-es'
import {useParams} from 'react-router-dom'

import {appendTranscriptSlice, hasNoSpeech} from '../utils/transcriptUtils'

import TranscriptContext, {
  TranscriptAction,
  TranscriptContextValue,
  TranscriptState,
} from './TranscriptContext'

function actionReducer(state: TranscriptState, action: TranscriptAction): TranscriptState {
  switch (action.type) {
    case 'reset':
      return {
        stage: action.stage || 'CHOOSE_SOURCE',
        redoOperations: [],
        undoOperations: [],
      }
    case 'setMetadata':
      return {...state, metadata: action.metadata}
    case 'setTranscript':
      return {...state, transcript: action.transcript}
    case 'appendSlice':
      return {
        ...state,
        transcript: appendTranscriptSlice(state.transcript, action.slice),
      }
    case 'patch':
      if (!state.transcript) {
        // eslint-disable-next-line no-console
        console.warn('Cannot patch transcript when it does not exist', action.operation)
        return state
      }

      return {
        ...state,
        transcript: applyPatches(state.transcript, action.operation.patches),
        ...(action.undoable || action.undoable === undefined
          ? {
              undoOperations: takeRight([...state.undoOperations, action.operation], 50),
              redoOperations: [],
            }
          : {}),
      }
    case 'saved':
      return {
        ...state,
        lastSaved: action.date,
      }
    case 'undo': {
      if (!state.transcript) {
        // eslint-disable-next-line no-console
        console.warn('Cannot patch transcript when it does not exist', action)
        return state
      }
      const {inversePatches} = action.operation

      return {
        ...state,
        transcript: applyPatches(state.transcript, inversePatches),
        redoOperations: takeRight([...state.redoOperations, action.operation], 50),
        undoOperations: state.undoOperations.slice(0, -1),
      }
    }
    case 'redo': {
      if (!state.transcript) {
        // eslint-disable-next-line no-console
        console.warn('Cannot patch transcript when it does not exist', action)
        return state
      }
      const {patches} = action.operation
      return {
        ...state,
        transcript: applyPatches(state.transcript, patches),
        undoOperations: takeRight([...state.undoOperations, action.operation], 50),
        redoOperations: state.redoOperations.slice(0, -1),
      }
    }
    case 'setMode': {
      return {...state, mode: action.mode}
    }
    case 'setStage': {
      return {...state, stage: action.stage}
    }
    default:
      return state
  }
}

/** Populate derived state keys */
function postReducer(state: TranscriptState): TranscriptState {
  const {hasNoSpeech: prevHasNoSpeech, ...rest} = state

  return {
    ...rest,
    ...(state.transcript && {
      hasNoSpeech: hasNoSpeech(state.transcript),
    }),
  }
}

function reducer(state: TranscriptState, action: TranscriptAction): TranscriptState {
  let nextState = actionReducer(state, action)

  if (nextState !== state) {
    nextState = postReducer(nextState)
  }

  return nextState
}

export default function TranscriptProvider(props: {children: React.ReactNode}): React.ReactNode {
  const {children} = props

  const {transcriptId} = useParams()

  const [state, dispatch] = useReducer(reducer, {
    stage: transcriptId ? 'POST_TRANSCRIPTION' : 'CHOOSE_SOURCE',
    undoOperations: [],
    redoOperations: [],
  })

  const value = useMemo<TranscriptContextValue>(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  )

  return <TranscriptContext.Provider value={value}>{children}</TranscriptContext.Provider>
}
