import {Button} from '@kensho/neo'
import clsx from 'clsx'
import {Dispatch, SetStateAction, useContext} from 'react'
import {useLogger} from '@kensho/lumberjack'

import prettyModified from '../../core/historicalTranscripts/HistoricalTranscriptsTable/util/prettyModified'
import getUserColor from '../../utils/getUserColor'
import {TranscriptPermissionsContext} from '../../core/transcription/TranscriptPermissionsProvider'
import {APITranscript} from '../../types/types'
import {produceOperation} from '../../utils/transcriptPatchUtils'
import {useDispatchEditOperation} from '../../core/transcription/transcript/DispatchEditOperationProvider'
import {KHighlight} from '../types'

import {HEIGHT} from './constants'

interface NeedsReviewDetailBoxProps {
  transcript: APITranscript
  active?: boolean
  index: number
  total: number
  kHighlight: KHighlight
  setActiveHighlightId: Dispatch<SetStateAction<string | undefined>>
  top: number
}

export default function NeedsReviewDetailBox({
  active = false,
  index,
  total,
  transcript,
  setActiveHighlightId,
  kHighlight,
  top,
}: NeedsReviewDetailBoxProps): React.ReactNode {
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const {dispatchEditOperation} = useDispatchEditOperation()
  const log = useLogger()

  if (!kHighlight) return null
  const {annotation} = kHighlight
  if (!annotation) return null
  const range = kHighlight.ranges[0]
  const {segments} = range
  const {createdOn, createdBy} = annotation
  if (!createdBy || !createdOn || !segments) return null
  const color = getUserColor(createdBy)
  const text = (() => {
    const fullText = kHighlight.text || ' '
    const maxLength = 100
    return fullText.length >= maxLength
      ? `${fullText.slice(0, Math.floor(maxLength / 2))}…${fullText.slice(-Math.floor(maxLength / 2))}`
      : fullText
  })()
  return (
    <div
      style={{
        top: `${top}px`,
        height: HEIGHT,
        transition: 'top 0.3s ease',
      }}
      className={clsx(
        'absolute w-full rounded-lg border border-gray-200 p-4 text-sm',
        active ? 'bg-white shadow-lg' : 'bg-blue-50',
      )}
      onClick={() => setActiveHighlightId(annotation.id)}
      onKeyDown={() => setActiveHighlightId(annotation.id)}
    >
      <div className="flex h-full flex-col justify-around">
        <div className="flex items-center gap-2">
          {/* // TODO: replace with UserPresence component */}
          <div
            style={{backgroundColor: color.fill, borderColor: color.border}}
            className={clsx('flex h-8 w-8 items-center justify-center rounded-full border text-sm')}
          >
            {createdBy[0].toUpperCase()}
          </div>
          <div className="flex flex-col">
            <div>{createdBy}</div>
            <div className="text-gray-500">{prettyModified(createdOn)}</div>
          </div>
        </div>
        <div className="break-all border-l-2 border-yellow-400 pl-2">{text}</div>
        <div className="flex items-center justify-between">
          <div className="text-xs text-gray-500">{total > 1 && `${index + 1} out of ${total}`}</div>
          <Button
            disabled={!transcriptPermissions.edit}
            size="small"
            icon="ArchiveBoxIcon"
            onClick={() => {
              if (!transcript) return
              const operation = produceOperation({
                action: {
                  type: 'delete-annotation',
                  annotationId: annotation.id,
                },
                transcript,
                transcriptSelection: null,
                log,
              })
              dispatchEditOperation(operation)
              setActiveHighlightId(undefined)
            }}
          >
            Remove highlight
          </Button>
        </div>
      </div>
    </div>
  )
}
