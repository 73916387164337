import {useLogger} from '@kensho/lumberjack'
import {Button, Tooltip} from '@kensho/neo'
import {useCallback, useContext} from 'react'

import TranscriptContext from '../../../providers/TranscriptContext'
import {TranscriptSelection} from '../../../types/types'
import {produceOperation} from '../../../utils/transcriptPatchUtils'
import {useDispatchEditOperation} from '../transcript/DispatchEditOperationProvider'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'
import UserContext from '../../../providers/UserContext'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import {prettyShortcutKey} from '../../../shortcuts/ShortcutSection'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'

interface Props {
  transcriptId?: string
  transcriptSelection: TranscriptSelection | null
}

export default function CreateNeedsReviewHighlightButton(props: Props): React.ReactNode {
  const {transcriptSelection, transcriptId} = props
  const {transcript} = useContext(TranscriptContext)
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const {dispatchEditOperation} = useDispatchEditOperation()
  const log = useLogger()
  const {user} = useContext(UserContext)

  const markSelectedForReview = useCallback(async () => {
    if (
      !transcriptId ||
      !transcript ||
      !transcriptPermissions.edit ||
      transcriptSelection?.type !== 'Range' ||
      !transcriptSelection.start ||
      !transcriptSelection.end ||
      !user
    )
      return

    const operation = produceOperation({
      action: {
        type: 'add-annotation',
        annotation: {
          source: 'scribe',
          knowledgeBase: 'custom',
          label: 'needs-review-highlight',
          type: 'comment',
          createdBy: user.email,
          createdOn: new Date().toISOString(),
        },
        ranges: [
          {
            start: {
              sliceIndex: transcriptSelection.start.sliceIndex,
              tokenIndex: transcriptSelection.start.tokenIndex,
            },
            end: {
              sliceIndex: transcriptSelection.end.sliceIndex,
              tokenIndex: transcriptSelection.end.tokenIndex,
            },
          },
        ],
      },
      transcript,
      transcriptSelection: null,
      log,
    })

    dispatchEditOperation(operation)
  }, [
    transcriptId,
    transcript,
    transcriptPermissions,
    transcriptSelection,
    dispatchEditOperation,
    log,
    user,
  ])
  const {keys} = SHORTCUTS.Edit.highlight

  useKeyboardShortcut(keys, () => {
    markSelectedForReview()
  })

  const label = `Highlight (${keys.map((key) => prettyShortcutKey(key)).join('')})`
  return (
    <Tooltip content={label} position="bottom">
      <Button
        disabled={
          !transcript || !transcriptPermissions.edit || transcriptSelection?.type !== 'Range'
        }
        onClick={markSelectedForReview}
        icon="PaintBrushIcon"
        minimal
        intent="primary"
        aria-label={label}
      />
    </Tooltip>
  )
}
