import {Button, Icon, Tooltip} from '@kensho/neo'
import clsx from 'clsx'
import {useContext} from 'react'

import Skeleton from '../../../components/Skeleton'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import {
  APITranscript,
  AsyncStatus,
  Mode,
  Stage,
  TranscriptMetadata,
  TranscriptSelection,
} from '../../../types/types'
import {OperationAction} from '../../../utils/transcriptPatchUtils'
import AlignSelectedButton from '../actions/AlignSelectedButton'
import CopyToClipboardButton from '../actions/CopyToClipboardButton'
import FootPedalButton from '../actions/FootPedalButton'
import ImportTranscriptButton from '../actions/ImportTranscriptButton'
import CreateNeedsReviewHighlightButton from '../actions/CreateNeedsReviewHighlightButton'
import ReviewButton from '../actions/ReviewButton'
import LastSavedStatus from '../LastSavedStatus'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

interface ToolbarProps {
  mode?: Mode
  metadata?: TranscriptMetadata
  stage: Stage
  transcript?: APITranscript
  transcriptId?: string
  onEditOperationError: (error: Error, action: OperationAction) => void
  transcriptSelection: TranscriptSelection | null
  updateTranscriptSelection: UpdateTranscriptSelectionType
  scrollTranscriptToTime: (timeMs: number) => Promise<void>
  undo: () => void
  redo: () => void
  backgroundAlignerStatus: AsyncStatus
  syncScrollToTime: boolean
  setSyncScrollToTime: React.Dispatch<React.SetStateAction<boolean>>
  scrollTranscriptToCurrentTime: () => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  currentTime: number
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
  hasMedia: boolean
}

function Toolbar(props: ToolbarProps): React.ReactNode {
  const {
    stage,
    mode,
    metadata,
    transcript,
    transcriptId,
    onEditOperationError,
    transcriptSelection,
    updateTranscriptSelection,
    scrollTranscriptToTime,
    undo,
    redo,
    backgroundAlignerStatus,
    syncScrollToTime,
    setSyncScrollToTime,
    seekMedia,
    currentTime,
    setPaused,
    hasMedia,
  } = props
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)

  if (mode !== 'REALTIME' && !metadata) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton height={20} width={200} />
        <Skeleton height={35} width="100%" />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-3 overflow-hidden">
        <LastSavedStatus />
        <span
          className={clsx(
            'inline-flex gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-brand-700 opacity-0 transition-all',
            backgroundAlignerStatus === 'pending' && 'opacity-100',
          )}
        >
          <Icon icon="ArrowPathIcon" size="small" /> Aligning timestamps…
        </span>
      </div>

      {stage === 'POST_TRANSCRIPTION' && !!transcript && (
        <div className="flex h-12 items-center overflow-hidden whitespace-nowrap rounded-lg border-[1px] border-gray-300 px-2">
          <div className="mr-2">
            <Tooltip content="Undo" position="bottom">
              <Button
                aria-label="Undo"
                icon="ArrowUturnLeftIcon"
                disabled={!transcriptPermissions.edit}
                minimal
                intent="primary"
                onClick={undo}
              />
            </Tooltip>
            <Tooltip content="Redo" position="bottom">
              <Button
                aria-label="Redo"
                icon="ArrowUturnRightIcon"
                disabled={!transcriptPermissions.edit}
                minimal
                intent="primary"
                onClick={redo}
              />
            </Tooltip>
          </div>
          <span className="mx-1 h-4 w-[1px] bg-gray-300" />
          {'hid' in navigator && navigator.hid && (
            <FootPedalButton
              seekMedia={seekMedia}
              currentTime={currentTime}
              setPaused={setPaused}
              hasMedia={hasMedia}
            />
          )}

          <Tooltip content="Keep current time in view" position="bottom">
            {/* TODO: switch to @kensho/neo toggle button when available https://github.kensho.com/kensho/neo/issues/118 */}
            <Button
              aria-label="Keep current time in view"
              icon="BarsArrowDownIcon"
              minimal
              intent={syncScrollToTime ? 'primary' : 'default'}
              onClick={() => setSyncScrollToTime(!syncScrollToTime)}
            />
          </Tooltip>
          <Tooltip content="Copy to clipboard" position="bottom">
            <CopyToClipboardButton />
          </Tooltip>
          <ReviewButton
            onEditOperationError={onEditOperationError}
            updateTranscriptSelection={updateTranscriptSelection}
            scrollTranscriptToTime={scrollTranscriptToTime}
          />
          <ImportTranscriptButton
            onEditOperationError={onEditOperationError}
            transcriptSelection={transcriptSelection}
            transcript={transcript}
            transcriptId={transcriptId}
          />
          <span className="mx-1 h-4 w-[1px] bg-gray-300" />
          <AlignSelectedButton
            transcriptId={transcriptId}
            transcriptSelection={transcriptSelection}
            updateTranscriptSelection={updateTranscriptSelection}
          />
          <CreateNeedsReviewHighlightButton
            transcriptId={transcriptId}
            transcriptSelection={transcriptSelection}
          />
        </div>
      )}
    </div>
  )
}

export default Toolbar
