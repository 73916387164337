import {useLogger} from '@kensho/lumberjack'
import {Tooltip, useToaster} from '@kensho/neo'
import {throttle} from 'lodash-es'
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {FileRejection} from 'react-dropzone'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import usePollTranscriptMetadata from '../../api/usePollTranscriptMetadata'
import KeyboardShortcutsIcon from '../../assets/keyboard.svg'
import ErrorDialog from '../../components/ErrorDialog'
import ResizableContainer, {ResizableContainerRef} from '../../components/ResizableContainer'
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import useMultiplayerContext from '../../hooks/useMultiplayerContext'
import useTranscriptSelection from '../../hooks/useTranscriptSelection'
import {MultiplayerProvider} from '../../providers/MultiplayerProvider'
import SpellCheckProvider from '../../providers/SpellCheckProvider'
import TranscriptContext from '../../providers/TranscriptContext'
import TranscriptProvider from '../../providers/TranscriptProvider'
import UserContext from '../../providers/UserContext'
import ShortcutDrawer from '../../shortcuts/ShortcutDrawer'
import SHORTCUTS from '../../shortcuts/shortcutRegistration'
import {
  APITranscriptToken,
  EditorOperation,
  Mode,
  RealtimeStatus,
  ScribeError,
  TranscriptMetadata,
  TranscriptSelection,
  TranscriptionConfiguration,
} from '../../types/types'
import {OperationAction} from '../../utils/transcriptPatchUtils'
import HistoricalTranscriptsTable from '../historicalTranscripts/HistoricalTranscriptsTable/HistoricalTranscriptsTable'

import ActionBar from './ActionBar'
import FileUpload from './FileUpload'
import PlaybackUploadDialog from './PlaybackUploadDialog'
import {
  TranscriptPermissionsContext,
  TranscriptPermissionsProvider,
} from './TranscriptPermissionsProvider'
import {PLAYBACK_RATES} from './actions/PlaybackRateButton'
import TimestampManager from './timestampManager/TimestampManager'
import {DispatchEditOperationProvider} from './transcript/DispatchEditOperationProvider'
import TranscriptContainer, {TranscriptContainerRef} from './transcript/TranscriptContainer'
import useRemoteTranscriptChanges from './transcript/useRemoteTranscriptChanges'
import useTranscriptEditQueue from './transcript/useTranscriptEditQueue'
import TranscriptionConfig from './transcriptionConfig/TranscriptionConfig'

function Transcription(): React.ReactNode {
  const {user} = useContext(UserContext)
  const {
    transcript,
    dispatch: transcriptContextDispatch,
    undoOperations,
    redoOperations,
    stage,
    mode,
  } = useContext(TranscriptContext)
  const {transcriptPermissions, setTranscriptPermissions} = useContext(TranscriptPermissionsContext)
  const {sendMessage, sessionId} = useMultiplayerContext()
  const navigate = useNavigate()
  const {transcriptId} = useParams()
  const setTranscriptId = useCallback(
    (nextTranscriptId?: string) => {
      if (nextTranscriptId !== transcriptId)
        navigate(`/transcription${nextTranscriptId ? `/${nextTranscriptId}` : ''}`)
    },
    [navigate, transcriptId],
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const toaster = useToaster()

  const [isShortcutOpen, setIsShortcutOpen] = useState(false)

  const [transcriptionConfiguration, setTranscriptionConfiguration] =
    useState<TranscriptionConfiguration>({})
  const [error, setError] = useState<ScribeError>()
  const [currentTime, setCurrentTime] = useState(0) // seconds
  const [duration, setDuration] = useState<number>() // seconds
  const [showTimestampManager, setShowTimestampManager] = useState(false)

  // media
  const [mediaFile, setMediaFile] = useState<File | undefined>()
  const [mediaPlayable, setMediaPlayable] = useState(true)
  const [paused, setPaused] = useState(true)
  const [playbackRate, setPlaybackRate] = useState(1)

  // transcript
  const [transcriptDownloaded, setTranscriptDownloaded] = useState(false)
  const transcriptContainerRef = useRef<TranscriptContainerRef>(null)
  const [isTranscriptFocused, setIsTranscriptFocused] = useState(false)

  // realtime
  const [realtimeStatus, setRealtimeStatus] = useState<RealtimeStatus>('unready')
  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>()

  const onPollMetadataSuccess = useCallback(
    (result: TranscriptMetadata) => {
      transcriptContextDispatch({type: 'setMetadata', metadata: result})
    },
    [transcriptContextDispatch],
  )
  const onPollMetadataError = useCallback((e: ScribeError) => setError(e), [])
  const [pollTranscriptMetadataCancel] = usePollTranscriptMetadata({
    transcriptId,
    disabled: Boolean(!user || mode === 'REALTIME'),
    onSuccess: onPollMetadataSuccess,
    onError: onPollMetadataError,
  })

  const resetAudioAndTranscript = useCallback((): void => {
    transcriptContextDispatch({type: 'reset'})
    setTranscriptionConfiguration({})
    setError(undefined)
    setCurrentTime(0)
    setDuration(undefined)
    setPaused(true)
    pollTranscriptMetadataCancel()
    setMediaFile(undefined)
    setTranscriptId()
    setTranscriptDownloaded(false)
    setMediaPlayable(true)
    setTranscriptPermissions({edit: true})
  }, [
    setTranscriptId,
    transcriptContextDispatch,
    pollTranscriptMetadataCancel,
    setTranscriptPermissions,
  ])

  // confirm before navigating away from page without downloading realtime transcript
  useEffect(() => {
    setTranscriptDownloaded(false)
  }, [transcript])

  useEffect(() => {
    const warnUnsavedTranscript = (event: BeforeUnloadEvent): void => {
      if (mode !== 'REALTIME' || !['POST_TRANSCRIPTION'].includes(stage) || transcriptDownloaded)
        return

      event.preventDefault()
      /* eslint-disable-next-line no-param-reassign */
      event.returnValue = 'Leave page and discard this transcript?'
    }

    window.addEventListener('beforeunload', warnUnsavedTranscript)
    return () => {
      window.removeEventListener('beforeunload', warnUnsavedTranscript)
    }
  }, [transcriptDownloaded, stage, mode])
  const showUnsavedTranscriptWarning = useMemo(
    () => mode === 'REALTIME' && ['POST_TRANSCRIPTION'].includes(stage) && !transcriptDownloaded,
    [transcriptDownloaded, stage, mode],
  )

  const handleFileSwitch = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
      if (acceptedFiles.length + fileRejections.length > 1) {
        toaster.show({
          label: `Only one audio/video file can be uploaded at a time`,
          intent: 'danger',
        })
        return
      }

      if (fileRejections.length) {
        toaster.show({
          label: `Unsupported file. Please upload a valid audio/video file`,
          intent: 'danger',
        })
        return
      }

      setMediaPlayable(true)
      setMediaFile(acceptedFiles[0])
    },
    [toaster],
  )

  const [mediaEle, setMediaEle] = useState<HTMLAudioElement | null>(null)

  const onMediaError = useCallback((e?: ScribeError) => {
    // if the file loads but is not playable, skip playback but still let them transcribe
    if (!e) {
      setMediaPlayable(false)
      return
    }

    setError(e)
  }, [])

  const seekMedia = useCallback(
    ({
      timeSeconds,
      play = true,
      scroll = true,
    }: {
      timeSeconds: number
      play?: boolean
      scroll?: boolean
    }): void => {
      if (mediaFile && mediaPlayable && mediaEle) {
        if (timeSeconds >= 0 && timeSeconds <= mediaEle.duration) {
          mediaEle.currentTime = timeSeconds
          if (play) {
            setPaused(false)
          }
          setCurrentTime(timeSeconds)
        } else if (timeSeconds <= 0) {
          mediaEle.currentTime = 0
          setCurrentTime(0)
        } else {
          mediaEle.currentTime = mediaEle.duration
          setCurrentTime(mediaEle.duration)
        }
      }
      if (scroll) transcriptContainerRef.current?.scrollTranscriptToTime(timeSeconds * 1000)
    },
    [mediaEle, mediaFile, mediaPlayable],
  )

  // set the stage to POST_TRANSCRIPTION in two scenarios
  // 1. after file has been batch uploaded progress to transcript view
  // 2. user navigates directly to /transcription/<transcript_id>
  useEffect(() => {
    if (transcriptId && mode !== 'REALTIME')
      transcriptContextDispatch({type: 'setStage', stage: 'POST_TRANSCRIPTION'})
  }, [transcriptId, mode, transcriptContextDispatch])

  // reset transcription state when we no longer have a transcriptId (for example, user hits the back button)
  useEffect(() => {
    if (!transcriptId && stage === 'POST_TRANSCRIPTION') {
      resetAudioAndTranscript()
    }
  }, [transcriptId, resetAudioAndTranscript, stage])

  const onClickToken = useCallback(
    (token: APITranscriptToken): void => {
      if (stage !== 'POST_TRANSCRIPTION') return
      if (paused) seekMedia({timeSeconds: token.startMs / 1000, play: false, scroll: false})
    },
    [seekMedia, stage, paused],
  )

  const onNewTranscriptConfig = useCallback(
    (configMode: Mode): void => {
      resetAudioAndTranscript()
      transcriptContextDispatch({type: 'setMode', mode: configMode})
      transcriptContextDispatch({type: 'setStage', stage: 'PRE_TRANSCRIPTION'})
      setTranscriptionConfiguration((prevTranscriptionConfiguration) => ({
        ...prevTranscriptionConfiguration,
        name: new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'short'}).format(
          new Date(),
        ),
      }))
    },
    [transcriptContextDispatch, resetAudioAndTranscript],
  )

  const {transcriptSelection, updateTranscriptSelection} = useTranscriptSelection(
    transcript,
    isTranscriptFocused,
  )
  const transcriptEditQueue = useTranscriptEditQueue({
    onError: (err: ScribeError) => setError(err),
    transcriptId,
    disabled: !transcriptPermissions.edit || stage !== 'POST_TRANSCRIPTION' || mode === 'REALTIME',
  })
  useRemoteTranscriptChanges()
  const dispatchEditOperation = useCallback(
    (operation: EditorOperation, undoable = true): void => {
      if (!transcriptPermissions.edit) return
      // update client state
      transcriptContextDispatch({type: 'patch', operation, undoable})
      // copy operation to queue to be sent to server
      transcriptEditQueue.push(operation.patches)
      if (!operation.selectionChangeDisabled) updateTranscriptSelection(operation.afterSelection)
    },
    [
      transcriptPermissions,
      transcriptEditQueue,
      transcriptContextDispatch,
      updateTranscriptSelection,
    ],
  )

  const log = useLogger()
  const onEditOperationError = useCallback(
    (editOperationError: Error, action: OperationAction): void => {
      log.error(editOperationError, {actionType: action.type, transcriptId})
    },
    [transcriptId, log],
  )

  function undo(): void {
    if (!transcriptPermissions.edit) return
    const last = undoOperations.at(-1)
    if (!last) return
    transcriptContextDispatch({type: 'undo', operation: last})
    transcriptEditQueue.push(last.inversePatches)

    if (!last.selectionChangeDisabled) {
      setIsTranscriptFocused(true)
      updateTranscriptSelection(last.beforeSelection, false)
    }
  }
  function redo(): void {
    if (!transcriptPermissions.edit) return
    const last = redoOperations.at(-1)
    if (!last) return
    transcriptContextDispatch({type: 'redo', operation: last})
    transcriptEditQueue.push(last.patches)

    if (!last.selectionChangeDisabled) {
      setIsTranscriptFocused(true)
      updateTranscriptSelection(last.afterSelection, false)
    }
  }

  useEffect(() => {
    const modeParam = searchParams.get('mode')
    if (modeParam === 'realtime') {
      onNewTranscriptConfig('REALTIME')
      searchParams.delete('mode')
      setSearchParams(searchParams)
    }
  }, [onNewTranscriptConfig, searchParams, setSearchParams])

  useKeyboardShortcut(
    SHORTCUTS.Audio.skipBackwards.keys,
    () => {
      seekMedia({timeSeconds: currentTime - 3})
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
      preventDefault: true,
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.playPause.keys,
    () => {
      setPaused(!paused)
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )

  useKeyboardShortcut(
    SHORTCUTS.Audio.increaseSpeed.keys,
    () => {
      setPlaybackRate(
        PLAYBACK_RATES[
          Math.min(PLAYBACK_RATES.length - 1, PLAYBACK_RATES.indexOf(playbackRate) + 1)
        ],
      )
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.decreaseSpeed.keys,
    () => {
      setPlaybackRate(PLAYBACK_RATES[Math.max(0, PLAYBACK_RATES.indexOf(playbackRate) - 1)])
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.skipForwards.keys,
    () => {
      seekMedia({timeSeconds: currentTime + 3})
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
      preventDefault: true,
    },
  )

  useKeyboardShortcut(
    SHORTCUTS.View.toggleShortcutDrawer.keys,
    () => setIsShortcutOpen((prev) => !prev),
    {preventDefault: false, ignoreModifiers: true, enableOnContentEditable: false},
  )

  const bottomBarContainerRef = useRef<ResizableContainerRef>(null)

  useKeyboardShortcut(
    SHORTCUTS.View.toggleTimeline.keys,
    () => {
      const nextShowTimestampManager = !showTimestampManager
      setShowTimestampManager(nextShowTimestampManager)

      if (nextShowTimestampManager) {
        bottomBarContainerRef.current?.setSize({height: 224})
      } else {
        bottomBarContainerRef.current?.setSize({height: 80})
      }
    },
    {
      preventDefault: false,
      enableOnContentEditable: false,
      enabled: stage === 'POST_TRANSCRIPTION' && !!transcript,
    },
  )

  const throttledSendCursorUpdate = useMemo(
    () =>
      throttle((selection: TranscriptSelection | null) => {
        if (selection && sessionId)
          sendMessage({
            type: 'cursor-update',
            payload: {start: selection.start, end: selection.end, sessionId},
          })
      }, 500),
    [sendMessage, sessionId],
  )

  useEffect(() => {
    throttledSendCursorUpdate(transcriptSelection)

    // periodically send cursor updates even when transcriptSelecition is unchanged
    const interval = window.setInterval(() => {
      throttledSendCursorUpdate(transcriptSelection)
    }, 3000)

    return () => {
      clearInterval(interval)
      throttledSendCursorUpdate.cancel()
    }
  }, [transcriptSelection, throttledSendCursorUpdate])

  return (
    <DispatchEditOperationProvider dispatchEditOperation={dispatchEditOperation}>
      <div className="flex h-[calc(100vh-80px)] flex-col overflow-hidden">
        <ErrorDialog isOpen={!!error} error={error} onClose={() => setError(undefined)} />

        <div className="m-auto mt-3 flex w-full max-w-[100vw] flex-auto justify-center gap-10 overflow-hidden">
          {['CHOOSE_SOURCE'].includes(stage) && (
            <HistoricalTranscriptsTable onNewTranscriptConfig={onNewTranscriptConfig} />
          )}

          {mode && ['PRE_TRANSCRIPTION', 'START_TRANSCRIPTION'].includes(stage) && (
            <TranscriptionConfig
              mediaFile={mediaFile}
              setMediaFile={setMediaFile}
              transcriptionConfiguration={transcriptionConfiguration}
              setTranscriptionConfiguration={setTranscriptionConfiguration}
              audioInputDeviceId={audioInputDeviceId}
              onAudioInputDeviceChange={setAudioInputDeviceId}
              setTranscriptId={setTranscriptId}
              resetAudioAndTranscript={resetAudioAndTranscript}
              setRealtimeStatus={setRealtimeStatus}
              realtimeStatus={realtimeStatus}
            />
          )}

          {['TRANSCRIPTION', 'POST_TRANSCRIPTION'].includes(stage) && (
            <>
              <TranscriptContainer
                ref={transcriptContainerRef}
                showPlayControls={Boolean(mediaFile)}
                mode={mode}
                stage={stage}
                currentTimeMs={currentTime * 1000}
                paused={paused}
                onClickToken={onClickToken}
                seekMedia={seekMedia}
                transcriptionConfiguration={transcriptionConfiguration}
                setTranscriptionConfiguration={setTranscriptionConfiguration}
                transcriptSelection={transcriptSelection}
                updateTranscriptSelection={updateTranscriptSelection}
                onEditOperationError={onEditOperationError}
                undo={undo}
                redo={redo}
                isTranscriptFocused={isTranscriptFocused}
                setIsTranscriptFocused={setIsTranscriptFocused}
                setPaused={setPaused}
                setTranscriptDownloaded={setTranscriptDownloaded}
                resetAudioAndTranscript={resetAudioAndTranscript}
                onNewTranscriptConfig={onNewTranscriptConfig}
                showUnsavedTranscriptWarning={showUnsavedTranscriptWarning}
              />
            </>
          )}
        </div>

        {[
          'PRE_TRANSCRIPTION',
          'START_TRANSCRIPTION',
          'TRANSCRIPTION',
          'POST_TRANSCRIPTION',
        ].includes(stage) && (
          <ResizableContainer
            ref={bottomBarContainerRef}
            className="max-h-56 flex-none border-t-[1px] border-gray-200"
            disabled={!transcript || stage !== 'POST_TRANSCRIPTION'}
            initialWidth="100vw"
            initialHeight={80}
            minHeight={80}
            resize={{north: true}}
            onResize={({height}) =>
              setShowTimestampManager(typeof height === 'number' && height > 80)
            }
          >
            {['POST_TRANSCRIPTION'].includes(stage) && (
              <>
                <ShortcutDrawer isOpen={isShortcutOpen} onClose={() => setIsShortcutOpen(false)} />
                <Tooltip content="Keyboard shortcuts">
                  <button
                    data-testid="keyboard-shortcuts-button"
                    className="absolute -top-14 right-5 z-10 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white shadow-md ring-1 ring-black ring-opacity-10 hover:bg-gray-50 active:bg-gray-100"
                    type="button"
                    onClick={() => setIsShortcutOpen((prev) => !prev)}
                  >
                    <img src={KeyboardShortcutsIcon} alt="keyboard" />
                  </button>
                </Tooltip>
              </>
            )}

            <FileUpload
              handleFileDrop={(acceptedFiles, fileRejections) =>
                handleFileSwitch(acceptedFiles, fileRejections)
              }
              enableDragState={false}
              disabled={mode === 'REALTIME'}
            >
              {({open}) => (
                <>
                  <PlaybackUploadDialog
                    stage={stage}
                    transcript={transcript}
                    transcriptId={transcriptId}
                    hasMedia={Boolean(mediaFile)}
                    openFileChooser={open}
                  />
                  <ActionBar
                    onMediaError={onMediaError}
                    setMediaEle={setMediaEle}
                    transcript={transcript}
                    transcriptId={transcriptId}
                    mediaFile={mediaFile}
                    mediaPlayable={mediaPlayable}
                    currentTime={currentTime}
                    seekMedia={seekMedia}
                    paused={paused}
                    setPaused={setPaused}
                    duration={duration}
                    playbackRate={playbackRate}
                    setPlaybackRate={setPlaybackRate}
                    setCurrentTime={setCurrentTime}
                    setMediaPlayable={setMediaPlayable}
                    setDuration={setDuration}
                    setError={setError}
                    setTranscriptId={setTranscriptId}
                    transcriptionConfiguration={transcriptionConfiguration}
                    transcriptContextDispatch={transcriptContextDispatch}
                    setMediaFile={setMediaFile}
                    audioInputDeviceId={audioInputDeviceId}
                    realtimeStatus={realtimeStatus}
                    setRealtimeStatus={setRealtimeStatus}
                    openFileChooser={open}
                  />
                </>
              )}
            </FileUpload>

            {['POST_TRANSCRIPTION'].includes(stage) && showTimestampManager && transcript && (
              <TimestampManager
                transcript={transcript}
                currentTimeMs={currentTime * 1000}
                mediaEle={mediaEle}
                paused={paused}
                seekMedia={seekMedia}
              />
            )}
          </ResizableContainer>
        )}
      </div>
    </DispatchEditOperationProvider>
  )
}

export default function TranscriptionWithContext(): React.ReactNode {
  return (
    <TranscriptProvider>
      <TranscriptPermissionsProvider>
        <MultiplayerProvider>
          <SpellCheckProvider>
            <Transcription />
          </SpellCheckProvider>
        </MultiplayerProvider>
      </TranscriptPermissionsProvider>
    </TranscriptProvider>
  )
}
