import {APITranscript, APITranscriptToken} from '../types/types'
import {getTextFromTranscriptSelection} from '../utils/transcriptUtils'

import {KHighlight} from './types'

export function getNeedsReviewHighlights(
  transcript: APITranscript,
  activeHighlightId: string | undefined,
): KHighlight[] {
  if (!transcript?.annotations) return []
  const tokensByAnnotation = new Map<
    string,
    {sliceIndex: number; tokenIndex: number; token: APITranscriptToken}[]
  >()

  transcript.sliceMeta.forEach((slice, sliceIndex) => {
    slice.tokenMeta.forEach((token, tokenIndex) => {
      token.annotations?.forEach((annotationReference) => {
        const annotation = transcript.annotations && transcript.annotations[annotationReference.id]
        if (!annotation || annotation.label !== 'needs-review-highlight') return
        if (!tokensByAnnotation.has(annotationReference.id)) {
          tokensByAnnotation.set(annotationReference.id, [])
        }
        tokensByAnnotation.get(annotationReference.id)?.push({sliceIndex, tokenIndex, token})
      })
    })
  })

  const needsReviewHighlights: KHighlight[] = []
  Object.values(transcript.annotations).forEach((annotation) => {
    const tokens = tokensByAnnotation.get(annotation.id)
    if (tokens && tokens.length > 0) {
      const startToken = tokens[0]
      const endToken = tokens[tokens.length - 1]
      const kh: KHighlight = {
        style: {
          backgroundColor:
            activeHighlightId === annotation.id
              ? 'rgba(246, 200, 90, 1)'
              : 'rgba(246, 200, 90, 0.3)',
        },
        ranges: [
          {
            start: {
              sliceIndex: startToken.sliceIndex,
              textOffset: 0,
              tokenIndex: startToken.tokenIndex,
              type: 'token',
            },
            end: {
              sliceIndex: endToken.sliceIndex,
              tokenIndex: endToken.tokenIndex,
              textOffset: endToken.token.transcript.length,
              type: 'token',
            },
            type: 'Range',
          },
        ],
        annotation,
      }
      kh.text = getTextFromTranscriptSelection(transcript, kh.ranges[0])
      needsReviewHighlights.push(kh)
    }
  })

  return needsReviewHighlights
}
