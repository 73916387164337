import {css} from '@emotion/react'
import {useCallback, useMemo} from 'react'

import {APITranscript, Mode, Stage} from '../../types/types'
import getSpeakerColor from '../../utils/getSpeakerColor'
import prettyDuration from '../../utils/prettyDuration'
import {getDuration} from '../../utils/transcriptUtils'

const THUMB_WIDTH_PX = 16

interface TimelineProps {
  hasMedia: boolean
  transcript?: APITranscript
  mode?: Mode
  stage: Stage
  currentTime?: number // seconds
  duration?: number // seconds
  onSeek: (time: number) => void
}

const timelineContainerCss = css`
  bottom: 0;
  padding: 0 16px;
`

const timelineCss = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
  cursor: pointer;
`

const baseLineCss = css`
  display: flex;
  position: relative;
  height: 4px;
  background: rgba(0, 0, 0, 0.16);
  width: 100%;
`

const speakerRegionCss = css`
  height: 100%;
  flex: 0 0 auto;
  z-index: 1;
  position: absolute;
`

const thumbCss = css`
  position: absolute;
  width: ${THUMB_WIDTH_PX}px;
  height: ${THUMB_WIDTH_PX}px;
  background: #09819f;
  z-index: 2;
`

const noPointerCss = css`
  cursor: default;
`

export default function Timeline(props: TimelineProps): React.ReactNode {
  const {hasMedia, transcript, currentTime, duration, mode, stage, onSeek} = props

  // seconds
  const transcriptDuration = useMemo(() => {
    if (!transcript) return 0
    return getDuration(transcript) / 1000
  }, [transcript])

  const onTimelineClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!transcriptDuration || !hasMedia) return
      const {left, width: eleWidth} = event.currentTarget.getBoundingClientRect()
      const position = (event.pageX - left) / eleWidth
      onSeek((duration || transcriptDuration) * position)
    },
    [onSeek, duration, transcriptDuration, hasMedia],
  )

  return (
    <div css={timelineContainerCss} className="flex flex-row items-center gap-4">
      {/* native audio/video element is mounted for accessible media controls */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        css={[timelineCss, !hasMedia && noPointerCss]}
        onClick={onTimelineClick}
        className="w-full"
      >
        <div css={baseLineCss}>
          {!!transcriptDuration &&
            (transcript?.sliceMeta || []).map((slice) => (
              <div
                key={`${slice.startMs}:${slice.durationMs}:${slice.accuracy}`}
                css={speakerRegionCss}
                style={{
                  background: getSpeakerColor(slice.speakerId, 'transparent'),
                  width: `${(slice.durationMs / 1000 / transcriptDuration) * 100}%`,
                  left: `${(slice.startMs / 1000 / transcriptDuration) * 100}%`,
                }}
              />
            ))}
        </div>
        {!(mode === 'REALTIME' && stage === 'TRANSCRIPTION') &&
          hasMedia &&
          duration &&
          currentTime !== undefined && (
            <div
              css={thumbCss}
              style={{
                left: `calc(${(currentTime / duration) * 100}% - ${THUMB_WIDTH_PX / 2}px)`,
              }}
              className="rounded-full"
            />
          )}
      </div>
      {currentTime !== undefined && !(mode === 'REALTIME' && stage === 'PRE_TRANSCRIPTION') && (
        <span className="min-w-28 whitespace-nowrap text-sm leading-4 text-black">
          <span data-testid="current-time">{prettyDuration(currentTime)}</span> /{' '}
          {prettyDuration(duration || transcriptDuration || currentTime)}
        </span>
      )}
    </div>
  )
}
