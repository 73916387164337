import React, {useMemo} from 'react'

import {APITranscript} from '../../types/types'
import {KHighlight} from '../types'

import NeedsReviewDetailBox from './NeedsReviewDetailBox'
import {TOTAL_HEIGHT} from './constants'

interface NeedsReviewDetailContainerProps {
  transcript?: APITranscript
  kHighlights: KHighlight[]
  activeHighlightId: string | undefined
  setActiveHighlightId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default function NeedsReviewDetailContainer({
  transcript,
  kHighlights,
  activeHighlightId,
  setActiveHighlightId,
}: NeedsReviewDetailContainerProps): React.ReactNode {
  const processedHighlights = useMemo(() => {
    if (kHighlights.length === 0) return null
    // sort highlights by the start of their range
    const sortedHighlights = kHighlights.sort((a, b) => {
      const {sliceIndex: aSlice, tokenIndex: aToken} = a.ranges[0].start
      const {sliceIndex: bSlice, tokenIndex: bToken} = b.ranges[0].start
      return aSlice !== bSlice ? aSlice - bSlice : aToken - bToken
    })

    const highlightsWithDetails: {
      kHighlight: KHighlight
      originalIndex: number
      top: number
    }[] = []

    sortedHighlights.forEach((kHighlight, originalIndex) => {
      const top = kHighlight.ranges[0]?.segments?.[0]?.y
      // filter out offscreen highlights that don't have segments to compute the top from
      if (top) {
        highlightsWithDetails.push({
          kHighlight,
          originalIndex,
          top,
        })
      }
    })

    // find the index of the active highlight if it exists
    const activeHighlightIndex = highlightsWithDetails.findIndex(
      ({kHighlight}) => kHighlight.annotation?.id === activeHighlightId,
    )
    // the pivot highlight is always positioned at it's original top value, all other highlights are adjusted to respect this.
    // use the active highlight as the pivot or default to the first highlight if there is no active highlight
    const pivot = activeHighlightIndex >= 0 ? activeHighlightIndex : 0

    for (let i = pivot + 1; i < highlightsWithDetails.length; i += 1) {
      const minTop = highlightsWithDetails[i - 1].top + TOTAL_HEIGHT
      highlightsWithDetails[i].top = Math.max(highlightsWithDetails[i].top, minTop)
    }

    for (let i = pivot - 1; i >= 0; i -= 1) {
      const maxTop = highlightsWithDetails[i + 1].top - TOTAL_HEIGHT
      highlightsWithDetails[i].top = Math.min(highlightsWithDetails[i].top, maxTop)
    }

    return {highlightsWithDetails, activeHighlightIndex}
  }, [kHighlights, activeHighlightId])

  return (
    <div
      className="relative w-64 overflow-hidden"
      // The container should be the height of the transcript plus the size of one box to guarantee the user can scroll
      // to boxes rendered next to the last few lines of the transcript
    >
      {transcript &&
        processedHighlights &&
        processedHighlights.highlightsWithDetails.map(({kHighlight, originalIndex, top}, i) => (
          <NeedsReviewDetailBox
            key={kHighlight.annotation?.id}
            active={processedHighlights.activeHighlightIndex === i}
            top={top}
            index={originalIndex}
            total={kHighlights.length}
            kHighlight={kHighlight}
            setActiveHighlightId={setActiveHighlightId}
            transcript={transcript}
          />
        ))}
    </div>
  )
}
