import {useQuery, UseQueryResult} from '@tanstack/react-query'

import {TranscriptMetadata} from '../types/types'
import camelCaseDeep from '../utils/camelCaseDeep'
import useAPIFetchNoAbort from '../api/useAPIFetchNoAbort'
import {TranscriptMetadataContentRange} from '../core/historicalTranscripts/HistoricalTranscriptsTable/types'

/**
 * Parses contentRange header into a ui friendly format
 */
export function parseContentRange(contentRange: string): TranscriptMetadataContentRange {
  const data = contentRange.split(' ')[1]
  if (data.startsWith('*/')) {
    return {
      startIndex: 0,
      endIndex: 0,
      totalTranscripts: 0,
      hasNextPage: false,
    }
  }
  const [currentRange, total] = data.split('/')
  const [startIndex, endIndex] = currentRange.split('-').map((i) => parseInt(i, 10))
  const max = parseInt(total, 10)
  return {
    startIndex,
    endIndex,
    totalTranscripts: max,
    hasNextPage: endIndex < max - 1,
  }
}

export default function useTranscriptsMetadataQuery(options: {
  page: string
  page_size: string
  name: string
  sort?: 'name' | 'started_on' | 'updated_on' | 'status' | 'media_type' | 'media_duration'
  direction?: 'ascending' | 'descending'
}): UseQueryResult<
  {
    json: TranscriptMetadata[]
    contentRange: TranscriptMetadataContentRange
  },
  Error
> {
  const apiFetchNoAbort = useAPIFetchNoAbort()
  return useQuery({
    queryKey: ['transcripts', options],
    queryFn: async () => {
      const undefinedRemoved = Object.fromEntries(
        Object.entries(options).filter(([, v]) => v !== undefined),
      ) as Record<string, string>
      const urlParams = new URLSearchParams(undefinedRemoved)
      const response = await apiFetchNoAbort.execute(`/api/v2/transcriptions?${urlParams}`)
      if (!response.ok) {
        throw new Error('Failed to fetch transcripts')
      }
      const json = camelCaseDeep(await response.json()) as TranscriptMetadata[]
      const rawContentRange = response.headers.get('content-range')
      if (!rawContentRange) throw new Error('No content range header found')
      const contentRange = parseContentRange(rawContentRange)
      return {json, contentRange}
    },
    refetchInterval: 5000,
  })
}
