import React, {Dispatch, SetStateAction} from 'react'

import {Mode, TranscriptionConfiguration} from '../../../../types/types'
import NewTranscriptMenu from '../../NewTranscriptMenu'
import DownloadTranscriptButton from '../../actions/DownloadTranscriptButton'

interface Props {
  mode?: Mode
  setTranscriptDownloaded: Dispatch<SetStateAction<boolean>>
  transcriptionConfiguration: TranscriptionConfiguration
  resetAudioAndTranscript: () => void
  onNewTranscriptConfig: (configMode: Mode) => void
  showUnsavedTranscriptWarning: boolean
}

export default function TranscriptRightSidebar(props: Props): React.ReactNode {
  const {
    mode,
    transcriptionConfiguration,
    setTranscriptDownloaded,
    resetAudioAndTranscript,
    onNewTranscriptConfig,
    showUnsavedTranscriptWarning,
  } = props

  return (
    <aside className="flex w-40 flex-col gap-4">
      {/* Download Transcript */}
      <DownloadTranscriptButton
        mode={mode}
        transcriptionConfiguration={transcriptionConfiguration}
        onDownload={() => setTranscriptDownloaded(true)}
      />
      {/* New Transcription */}
      <NewTranscriptMenu
        setTranscriptDownloaded={setTranscriptDownloaded}
        onNewTranscriptConfig={onNewTranscriptConfig}
        resetAudioAndTranscript={resetAudioAndTranscript}
        showUnsavedTranscriptWarning={showUnsavedTranscriptWarning}
      />
    </aside>
  )
}
