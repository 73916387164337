/* eslint-disable no-param-reassign */
import {Logger} from '@kensho/lumberjack'
import Graphemer from 'graphemer'
import {Draft, produceWithPatches} from 'immer'
import {cloneDeep} from 'lodash-es'

import {DURATION_PER_CHAR_MS, DURATION_TOKEN_GAP_MS} from '../core/transcription/constants'
import {
  APITranscript,
  APITranscriptSlice,
  APITranscriptToken,
  Annotation,
  EditorOperation,
  SpeakerInfo,
  TranscriptSelection,
  TranscriptSelectionNode,
} from '../types/types'

import assertNever from './assertNever'
import getUniqueId from './getUniqueId'
import {IndexedToken, getTokenRange, indexFromPath} from './transcriptUtils'

interface InsertAction {
  type: 'insert-text'
  data: string
}

interface DeleteAction {
  type: 'delete-text'
  direction?: 'forward' | 'backward'
}

interface ReplaceTokensAction {
  type: 'replace-tokens'
  data: IndexedToken[]
}

interface ReplaceTranscriptAction {
  type: 'replace-transcript'
  data: APITranscript
}

interface SplitSliceAction {
  type: 'split-slice'
}

interface UpdateSpeakerAction {
  type: 'update-speaker'
  speakerId: number
  data: Partial<SpeakerInfo>
}

interface AddSpeakerAction {
  type: 'add-speaker'
  data: SpeakerInfo
}

interface DeleteSpeakerAction {
  type: 'delete-speaker'
  speakerId: number
}

interface ChangeSliceSpeakerAction {
  type: 'change-slice-speaker'
  speakerId: number | null
  data?: SpeakerInfo
  sliceIndex: number
}

export interface UpdateAccuracyAction {
  type: 'update-accuracy'
  startToken: {sliceIndex: number; tokenIndex: number}
  endToken: {sliceIndex: number; tokenIndex: number}
  accuracy: number
}

export interface AlignTokensAction {
  type: 'align-tokens'
  ranges: {
    range: {
      startMs?: number
      startToken?: string // JSON pointer
      endMs?: number
      endToken?: string // JSON pointer
    }
    tokens: APITranscriptToken[]
  }[]
}
/**
 * Annotations can have locations at the token or slice level.
 * For a slice-level annotation there will be no tokenIndex
 */
export interface AnnotationActionRange {
  start: {sliceIndex: number; tokenIndex?: number}
  end: {sliceIndex: number; tokenIndex?: number}
}

export interface AddAnnotationAction {
  type: 'add-annotation'
  annotation: Omit<Annotation, 'id'>
  annotationId?: string
  ranges?: AnnotationActionRange[]
}

export interface UpdateAnnotationAction {
  type: 'update-annotation'
  annotation?: Annotation
  annotationId: Annotation
  ranges?: AnnotationActionRange[]
}

export interface DeleteAnnotationAction {
  type: 'delete-annotation'
  annotationId: string
  ranges?: AnnotationActionRange[]
}

export type OperationAction =
  | InsertAction
  | DeleteAction
  | ReplaceTokensAction
  | ReplaceTranscriptAction
  | SplitSliceAction
  | UpdateSpeakerAction
  | AddSpeakerAction
  | DeleteSpeakerAction
  | ChangeSliceSpeakerAction
  | UpdateAccuracyAction
  | AlignTokensAction
  | AddAnnotationAction
  | UpdateAnnotationAction
  | DeleteAnnotationAction

/** Returns the startMs and durationMs that will fit all of the slice's tokens */
function calcSliceTimes(slice: APITranscriptSlice): {startMs: number; durationMs: number} {
  if (!slice.tokenMeta.length) return {startMs: Math.max(0, slice.startMs), durationMs: 0}

  // reduce over all tokens in case some are out of order with respect to time
  const times = slice.tokenMeta.reduce(
    (acc, token) => {
      if (token.startMs === 0 && token.durationMs === 0) return acc
      if (token.startMs < acc.startMs) acc.startMs = token.startMs
      if (token.startMs + token.durationMs > acc.startMs + acc.durationMs) {
        acc.durationMs = token.startMs + token.durationMs - acc.startMs
      }
      return acc
    },
    {startMs: slice.tokenMeta[0].startMs, durationMs: slice.tokenMeta[0].durationMs},
  )

  // ensure we're not returning negative values
  times.startMs = Math.max(0, times.startMs)
  times.durationMs = Math.max(0, times.durationMs)

  return times
}

function mergeTokens(token1: APITranscriptToken, token2: APITranscriptToken): APITranscriptToken {
  const mergedToken = {
    ...token1,
    transcript: `${token1.transcript}${token2.transcript}`,
    aligned: false,
  }

  // approximate duration based on the length of the text, should get refined later by alignment
  mergedToken.durationMs =
    mergedToken.transcript.replace(/[^a-z0-9]+/gi, '').length * DURATION_PER_CHAR_MS

  return mergedToken
}

function mergeSlices(slice1: APITranscriptSlice, slice2: APITranscriptSlice): APITranscriptSlice {
  const mergedSlice = {
    ...slice1,
    transcript: `${slice1.transcript} ${slice2.transcript}`,
    accuracy: (slice1.accuracy + slice2.accuracy) / 2,
    tokenMeta: [...slice1.tokenMeta, ...slice2.tokenMeta],
  }

  // update slice timings
  const mergedSliceTimings = calcSliceTimes(mergedSlice)
  mergedSlice.startMs = mergedSliceTimings.startMs
  mergedSlice.durationMs = mergedSliceTimings.durationMs

  return mergedSlice
}

/** Reduce all slice token text into a single string */
function accumulateSliceText(slice: APITranscriptSlice): string {
  return slice.tokenMeta.reduce((acc, token, i) => {
    acc += token.transcript
    if (i < slice.tokenMeta.length - 1) acc += ' '

    return acc
  }, '')
}

export function deleteTextRange(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
): void {
  if (!operation.afterSelection?.start || !operation.afterSelection?.end) return

  const {
    type: startType,
    sliceIndex: startSliceIndex,
    tokenIndex: startTokenIndex,
    textOffset: startTextOffset,
  } = operation.afterSelection.start
  const {
    type: endType,
    sliceIndex: endSliceIndex,
    tokenIndex: endTokenIndex,
    textOffset: endTextOffset,
  } = operation.afterSelection.end

  const startSlice = draftTranscript.sliceMeta[startSliceIndex]
  const startSliceOriginalLength = startSlice.tokenMeta.length
  const endSlice = draftTranscript.sliceMeta[endSliceIndex]
  const startToken = startSlice.tokenMeta[startTokenIndex]
  const endToken = endSlice.tokenMeta[endTokenIndex]

  const isPartialStartToken = startType === 'token' && startTextOffset > 0
  const isPartialEndToken = endType === 'token' && endTextOffset < endToken.transcript.length
  const isPartialStartSlice =
    startTokenIndex > 0 ||
    isPartialStartToken ||
    (startSliceIndex === endSliceIndex &&
      (isPartialEndToken || endTokenIndex < startSlice.tokenMeta.length - 1))
  const isPartialEndSlice =
    endTokenIndex < endSlice.tokenMeta.length - 1 ||
    isPartialEndToken ||
    (startSliceIndex === endSliceIndex && (isPartialStartToken || startTokenIndex > 0))

  // remove all slices spanned by the selection
  draftTranscript.sliceMeta.splice(startSliceIndex, endSliceIndex - startSliceIndex + 1)

  if (startSliceIndex === endSliceIndex && !isPartialStartSlice && !isPartialEndSlice) {
    // if the selection is the entirety of a single slice, add it back
    draftTranscript.sliceMeta.splice(startSliceIndex, 0, startSlice)
  } else if (isPartialStartSlice && isPartialEndSlice) {
    draftTranscript.sliceMeta.splice(
      startSliceIndex,
      0,
      startSlice !== endSlice ? mergeSlices(startSlice, endSlice) : startSlice,
    )
  } else if (isPartialStartSlice) {
    // start slice is partial, add it back
    draftTranscript.sliceMeta.splice(startSliceIndex, 0, startSlice)
  } else if (isPartialEndSlice) {
    // end slice is partial, add it back
    draftTranscript.sliceMeta.splice(startSliceIndex + 1, 0, endSlice)
  } else if (draftTranscript.sliceMeta.length === 0 && !isPartialEndSlice && !isPartialStartSlice) {
    // if we've deleted all slices, add a new empty slice
    draftTranscript.sliceMeta.push({
      speakerId: -1,
      speakerAccuracy: 1,
      accuracy: 1,
      startMs: 0,
      durationMs: 1,
      transcript: '',
      tokenMeta: [
        {
          transcript: ' ',
          startMs: 0,
          durationMs: 1,
          accuracy: 1,
          aligned: false,
        },
      ],
    })
    if (!draftTranscript.speakers[-1]) {
      draftTranscript.speakers[-1] = {
        name: 'Unknown speaker',
      }
    }
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex: 0,
      tokenIndex: 0,
      textOffset: 0,
    }
  } else {
    const precedingSliceIndex = Math.max(0, startSliceIndex - 1)
    const precedingTokenIndex = draftTranscript.sliceMeta[precedingSliceIndex].tokenMeta.length - 1
    // slice [slice] slice -> slice| slice
    // [first_slice] slice -> |slice
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex: precedingSliceIndex,
      tokenIndex: startSliceIndex === 0 ? 0 : precedingTokenIndex,
      textOffset:
        startSliceIndex === 0
          ? 0
          : draftTranscript.sliceMeta[precedingSliceIndex].tokenMeta[precedingTokenIndex].transcript
              .length,
    }
  }

  if (startSliceIndex === endSliceIndex && !isPartialStartSlice && !isPartialEndSlice) {
    // if the selection is the entirety of a single slice, remove its existing tokens

    const startSliceFirstToken = startSlice.tokenMeta[0] || {
      transcript: ' ',
      startMs: startSlice.startMs,
      durationMs: 1,
      accuracy: 1,
      aligned: false,
    }
    startSliceFirstToken.transcript = ' '
    startSlice.tokenMeta = [startSliceFirstToken]
    draftTranscript.sliceMeta[startSliceIndex].transcript = accumulateSliceText(startSlice)
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex: startSliceIndex,
      tokenIndex: 0,
      textOffset: 0,
    }
  } else if (isPartialStartSlice || isPartialEndSlice) {
    // if the slice(s) are only partially deleted handle which tokens to remove

    let removedTokens: APITranscriptToken[] = []
    // remove all tokens spanned by the selection
    if (startSliceIndex === endSliceIndex) {
      removedTokens = draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(
        startType === 'token-space' ? startTokenIndex + 1 : startTokenIndex,
        endTokenIndex - (startType === 'token-space' ? startTokenIndex + 1 : startTokenIndex) + 1,
      )
    } else if (isPartialStartSlice && isPartialEndSlice) {
      // we've already merged the slices together so we need to remove the tokens from the selection
      removedTokens = draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(
        startType === 'token-space' ? startTokenIndex + 1 : startTokenIndex,
        startSliceOriginalLength -
          (startType === 'token-space' ? startTokenIndex + 1 : startTokenIndex) +
          (endTokenIndex + 1),
      )
    } else if (isPartialStartSlice) {
      removedTokens = draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(
        startType === 'token-space' ? startTokenIndex + 1 : startTokenIndex,
      )
    } else if (isPartialEndSlice) {
      removedTokens = draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(
        0,
        endTokenIndex + 1,
      )
    }

    if (isPartialStartToken && isPartialEndToken) {
      if (startToken !== endToken) {
        // tok[en1 t]oken2 -> tok|oken2
        // if the start or end tokens are only partially deleted, add the merged token back
        const mergedToken = mergeTokens(startToken, endToken)
        mergedToken.transcript = `${startToken.transcript.slice(
          0,
          startTextOffset,
        )}${endToken.transcript.slice(Math.max(0, endTextOffset))}`
        mergedToken.accuracy = 1

        draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, mergedToken)
      } else {
        // t[ok]en -> t|en
        // range falls withn the same token, update the token's transcript and add it back
        startToken.transcript = `${startToken.transcript.slice(
          0,
          startTextOffset,
        )}${startToken.transcript.slice(endTextOffset)}`
        startToken.accuracy = 1
        draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, startToken)
      }
      // update the selection to the start of the range
      operation.afterSelection.end = {
        ...operation.afterSelection.start,
      }
    } else if (
      isPartialStartToken &&
      endType === 'token-space' &&
      endTextOffset === 1 &&
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex]
    ) {
      // token[1 ]token2 -> token|token2
      // tok[en1 token2 ]token3 -> tok|token3
      startToken.transcript = startToken.transcript.slice(0, startTextOffset)
      const nextToken = mergeTokens(
        startToken,
        draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex],
      )
      nextToken.accuracy = 1
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 1, nextToken)
      operation.afterSelection.end = cloneDeep(operation.afterSelection.start)
    } else if (
      isPartialStartToken &&
      endType === 'token-space' &&
      endTextOffset === 1 &&
      !draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex]
    ) {
      // token[1 ] -> token|
      startToken.transcript = startToken.transcript.slice(0, startTextOffset)
      startToken.accuracy = 1
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, startToken)
      operation.afterSelection.end = cloneDeep(operation.afterSelection.start)
    } else if (isPartialStartToken) {
      // to[ken1 token2] -> to|
      startToken.transcript = startToken.transcript.slice(0, startTextOffset)
      startToken.accuracy = 1
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, startToken)
      operation.afterSelection.end = cloneDeep(operation.afterSelection.start)
    } else if (isPartialEndToken && startType === 'token-space' && startTextOffset === 0) {
      // token1[ t]oken2 -> token1|oken2
      // token1[ token2 to]ken3 -> token1|ken3
      const nextToken = mergeTokens(startToken, endToken)
      nextToken.accuracy = 1
      nextToken.transcript =
        nextToken.transcript.slice(0, startToken.transcript.length) +
        nextToken.transcript.slice(
          startToken.transcript.length + operation.afterSelection.end.textOffset,
        )
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 1, nextToken)
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset: startToken.transcript.length,
      }
    } else if (isPartialEndToken) {
      // token1 [token2 to]ken3 -> token1 |ken3
      endToken.transcript = endToken.transcript.slice(endTextOffset)
      endToken.accuracy = 1
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, endToken)
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset: 0,
      }
    } else if (
      startType === 'token-space' &&
      endType === 'token-space' &&
      startTextOffset === 0 &&
      endTextOffset === 1
    ) {
      // token1[ ]token2 -> token1|token2
      // token1[ token2 token3 ]token4 -> token1|token4
      // last_token[ ] -> last_token|
      const nextToken = draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex + 1]
        ? mergeTokens(
            startToken,
            draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex + 1],
          )
        : cloneDeep(startToken)
      nextToken.accuracy = 1
      const originalLeftTokenLength = startToken.transcript.length

      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 2, nextToken)

      // set the text offset to the length of the original first token
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset: originalLeftTokenLength,
      }
    } else if (
      startType === 'token' &&
      endType === 'token' &&
      startTokenIndex === draftTranscript.sliceMeta[startSliceIndex].tokenMeta.length
    ) {
      // token1 [last_slice_token] -> token1 |
      operation.afterSelection.end = {
        type: 'token-space',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex - 1,
        textOffset: 1,
      }
    } else if (
      (startType === 'token' && endType === 'token') ||
      (startType === 'token-space' &&
        startTextOffset === 1 &&
        endType === 'token-space' &&
        endTextOffset === 0)
    ) {
      // token1 [token2] token3 -> token1 | token3
      // token1 [token2 token3] token4 -> token1 | token4
      // [first_slice_token] second_token -> | second_token
      const precedingToken =
        draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex - 1]
      const nextToken = {
        transcript: ' ',
        startMs:
          removedTokens[0]?.startMs || precedingToken.startMs + precedingToken.durationMs + 1,
        durationMs: removedTokens[0]?.durationMs || 1,
        accuracy: 1,
        aligned: false,
      }
      draftTranscript.sliceMeta[startSliceIndex].tokenMeta.splice(startTokenIndex, 0, nextToken)

      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset: 0,
      }
    } else if (startType === 'token-space' && endType === 'token') {
      // token1[ token2] token3 -> token1| token3
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset:
          draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex].transcript.length,
      }
    } else if (
      startType === 'token' &&
      endType === 'token-space' &&
      endTextOffset === 1 &&
      startTokenIndex === 0
    ) {
      // [first_slice_token ]token -> |token
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: startSliceIndex,
        tokenIndex: startTokenIndex,
        textOffset: 0,
      }
    } else if (startType === 'token' && endType === 'token-space' && endTextOffset === 1) {
      // token1 [token2 ]token3 -> token1 |token3
      // token [last_slice_token ] -> token |
      operation.afterSelection.end = {
        type: 'token-space',
        sliceIndex: startSliceIndex,
        tokenIndex: Math.max(0, startTokenIndex - 1),
        textOffset: 1,
      }
    } else if (startType === 'token-space' && endType === 'token-space') {
      // token1 [token2 ]token3 -> token1 |token3
      // token1[ token2 ]token3 -> token1|token3
    }

    // update slice transcript text
    draftTranscript.sliceMeta[startSliceIndex].transcript = accumulateSliceText(
      draftTranscript.sliceMeta[startSliceIndex],
    )
  }

  // we've adjusted the afterSelection.end above and since we'll always end with a caret selection
  // we need to clone to afterSelection.start
  operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
  operation.afterSelection.type = 'Caret'
}

export function deleteText(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action?: DeleteAction,
): void {
  if (!operation.afterSelection?.end) return

  if (operation.afterSelection.type === 'Range') {
    deleteTextRange(operation, draftTranscript)
    return
  }

  // move the selection forward by one character and then handle as regular backward delete
  if (action?.direction === 'forward' && operation.afterSelection.type === 'Caret') {
    if (
      operation.afterSelection.end.type === 'token-space' &&
      operation.afterSelection.end.textOffset === 0
    ) {
      // beginning of token-space, move to end of space
      // foo| bar -> foo |bar
      operation.afterSelection.end.textOffset = 1
    } else if (
      operation.afterSelection.end.type === 'token-space' &&
      operation.afterSelection.end.textOffset === 1 &&
      draftTranscript.sliceMeta[operation.afterSelection.end.sliceIndex].tokenMeta[
        operation.afterSelection.end.tokenIndex + 1
      ]
    ) {
      // end of token-space, move to start of next token
      // foo |bar -> foo b|ar
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: operation.afterSelection.end.sliceIndex,
        tokenIndex: operation.afterSelection.end.tokenIndex + 1,
        textOffset: 1,
      }
    } else if (
      operation.afterSelection.end.type === 'token-space' &&
      operation.afterSelection.end.textOffset === 1 &&
      !draftTranscript.sliceMeta[operation.afterSelection.end.sliceIndex + 1]?.tokenMeta[0]
    ) {
      // end of slice and no next slice, noop
      return
    } else if (
      operation.afterSelection.end.type === 'token-space' &&
      operation.afterSelection.end.textOffset === 1 &&
      draftTranscript.sliceMeta[operation.afterSelection.end.sliceIndex + 1]?.tokenMeta[0]
    ) {
      // end of slice, move to start of next slice
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: operation.afterSelection.end.sliceIndex + 1,
        tokenIndex: 0,
        textOffset: 0,
      }
    } else if (
      operation.afterSelection.end.type === 'token' &&
      operation.afterSelection.end.textOffset ===
        draftTranscript.sliceMeta[operation.afterSelection.end.sliceIndex].tokenMeta[
          operation.afterSelection.end.tokenIndex
        ].transcript.length
    ) {
      // end of token, move to end of token-space
      // foo| bar -> foo |bar
      operation.afterSelection.end = {
        type: 'token-space',
        sliceIndex: operation.afterSelection.end.sliceIndex,
        tokenIndex: operation.afterSelection.end.tokenIndex,
        textOffset: 1,
      }
    } else {
      // within token, move to next character
      // b|ar -> ba|r
      const splitter = new Graphemer()
      const moveOffset =
        splitter
          .splitGraphemes(
            draftTranscript.sliceMeta[operation.afterSelection.end.sliceIndex].tokenMeta[
              operation.afterSelection.end.tokenIndex
            ].transcript.slice(0, operation.afterSelection.end.textOffset),
          )
          .pop()?.length || 1

      operation.afterSelection.end = {
        ...operation.afterSelection.end,
        textOffset: operation.afterSelection.end.textOffset + moveOffset,
      }
    }

    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
  }

  const {type, sliceIndex, tokenIndex, textOffset} = operation.afterSelection.end

  const slice = draftTranscript.sliceMeta[sliceIndex]
  const token = slice.tokenMeta[tokenIndex]

  // figure out how many characters to move the caret back by
  // this is necessary because some grapheme clusters are multiple characters long
  // because a visual "letter" can be made up of multiple Unicode characters
  // for example: "👩‍👩‍👧‍👦" is a single grapheme cluster but 11 characters long
  const splitter = new Graphemer()
  const moveOffset =
    splitter.splitGraphemes(token.transcript.slice(0, textOffset)).pop()?.length || 1

  // if the slice will be empty remove it entirely
  if (
    draftTranscript.sliceMeta.length > 1 &&
    slice.tokenMeta.length <= 1 &&
    slice.tokenMeta[0].transcript.trim() === ''
  ) {
    draftTranscript.sliceMeta.splice(sliceIndex, 1)
    if (sliceIndex > 0) {
      // set the selection to the end of the previous slice if there was one before
      operation.afterSelection.type = 'Caret'
      operation.afterSelection.end = {
        type: 'token-space',
        sliceIndex: sliceIndex - 1,
        tokenIndex: draftTranscript.sliceMeta[sliceIndex - 1].tokenMeta.length - 1,
        textOffset: 1,
      }
      operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
    } else {
      // otherwise set it to the beginning of the next slice
      operation.afterSelection.type = 'Caret'
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex,
        tokenIndex: 0,
        textOffset: 0,
      }
      operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
    }

    return
  }

  // if we are deleting at the start of a slice, merge with previous slice
  if (tokenIndex === 0 && textOffset === 0) {
    // if this is the first slice then noop
    if (sliceIndex === 0) return

    // if the preceding slice is empty remove it entirely
    if (
      !draftTranscript.sliceMeta[sliceIndex - 1].tokenMeta.some((prevSliceToken) =>
        prevSliceToken.transcript.trim(),
      )
    ) {
      // keep the selection at the start but less one for the slice's new index
      operation.afterSelection.type = 'Caret'
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex: sliceIndex - 1,
        tokenIndex: 0,
        textOffset: 0,
      }
      operation.afterSelection.start = cloneDeep(operation.afterSelection.end)

      draftTranscript.sliceMeta.splice(sliceIndex - 1, 1)

      return
    }

    // set the selection to the end of the previous slice if there was one before
    operation.afterSelection.type = 'Caret'
    operation.afterSelection.end = {
      type: 'token-space',
      sliceIndex: sliceIndex - 1,
      tokenIndex: draftTranscript.sliceMeta[sliceIndex - 1].tokenMeta.length - 1,
      textOffset: 1,
    }
    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)

    draftTranscript.sliceMeta.splice(
      sliceIndex - 1,
      2,
      mergeSlices(draftTranscript.sliceMeta[sliceIndex - 1], draftTranscript.sliceMeta[sliceIndex]),
    )

    return
  }

  // if we will delete a space between tokens merge them together
  if ((type === 'token-space' && textOffset === 1) || (type === 'token' && textOffset === 0)) {
    const leftHandTokenIndex = type === 'token' ? tokenIndex - 1 : tokenIndex

    operation.afterSelection.type = 'Caret'
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex,
      tokenIndex: leftHandTokenIndex,
      textOffset: slice.tokenMeta[leftHandTokenIndex].transcript.length,
    }
    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)

    const nextToken = slice.tokenMeta[leftHandTokenIndex + 1]
      ? mergeTokens(
          draftTranscript.sliceMeta[sliceIndex].tokenMeta[leftHandTokenIndex],
          draftTranscript.sliceMeta[sliceIndex].tokenMeta[leftHandTokenIndex + 1],
        )
      : cloneDeep(draftTranscript.sliceMeta[sliceIndex].tokenMeta[leftHandTokenIndex])
    nextToken.accuracy = 1
    draftTranscript.sliceMeta[sliceIndex].tokenMeta.splice(leftHandTokenIndex, 2, nextToken)

    // sync the text of the new slice
    slice.transcript = accumulateSliceText(slice)

    return
  }

  // remove the character from the token

  token.transcript = `${token.transcript.slice(0, textOffset - moveOffset)}${token.transcript.slice(
    textOffset,
  )}`
  token.accuracy = 1

  if (!token.transcript && tokenIndex > 0) {
    // if the token will be empty, remove it and move the saved caret position to the token-space
    slice.tokenMeta.splice(tokenIndex, 1)

    operation.afterSelection.type = 'Caret'
    operation.afterSelection.end = {
      type: 'token-space',
      sliceIndex,
      tokenIndex: tokenIndex - 1,
      textOffset: 1,
    }
    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
  } else if (!token.transcript && tokenIndex === 0) {
    // if the token will be empty but it is the only one in the slice, replace contents with space
    operation.afterSelection.type = 'Caret'
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex,
      tokenIndex,
      textOffset: 0,
    }
    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
    token.transcript = ' '
  } else {
    // otherwise if a token won't be completely removed we can just move the saved caret position back by one
    operation.afterSelection.type = 'Caret'
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex,
      tokenIndex,
      textOffset: textOffset - moveOffset,
    }
    operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
  }

  // sync the text of the slice
  slice.transcript = accumulateSliceText(slice)
}

export function splitSlice(operation: EditorOperation, draftTranscript: APITranscript): void {
  // if the transcript is empty append a new slice
  if (!draftTranscript.sliceMeta.length) {
    const newSlice = {
      speakerId: -1,
      speakerAccuracy: 1,
      accuracy: 1,
      startMs: 0,
      durationMs: 1,
      transcript: '',
      tokenMeta: [
        {
          transcript: ' ',
          startMs: 0,
          durationMs: 1,
          accuracy: 1,
          aligned: false,
        },
      ],
    }
    draftTranscript.sliceMeta.push(newSlice)

    // if the transcript has no existing speaker infos add a new one for the speaker
    if (!draftTranscript.speakers?.[newSlice.speakerId]) {
      draftTranscript.speakers = draftTranscript.speakers || {}
      draftTranscript.speakers[newSlice.speakerId] = {
        name: 'Unknown Speaker',
      }
    }

    return
  }

  if (!operation.afterSelection?.end) return

  const {afterSelection} = operation

  if (afterSelection.type === 'Range') {
    deleteText(operation, draftTranscript)
  }

  const {type, sliceIndex, tokenIndex, textOffset} = afterSelection.end as TranscriptSelectionNode

  const firstSlice = draftTranscript.sliceMeta[sliceIndex]
  const secondSlice = {...firstSlice}

  const secondTokens = firstSlice.tokenMeta.splice(tokenIndex + 1)
  secondSlice.tokenMeta = secondTokens
  draftTranscript.sliceMeta.splice(sliceIndex + 1, 0, secondSlice)

  const tokenLength = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.length
  if (type === 'token' && textOffset > 0 && textOffset < tokenLength) {
    // if the caret was in the middle of the token split the token and append both appropriately
    const firstToken = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
    const secondToken = {...firstToken}

    // split the timestamp of the token proportional to character offset
    const originalDuration = firstToken.durationMs
    firstToken.durationMs = Math.floor(firstToken.durationMs * (textOffset / tokenLength))
    secondToken.startMs = firstToken.startMs + originalDuration
    secondToken.durationMs = originalDuration - firstToken.durationMs

    firstToken.transcript = firstToken.transcript.slice(0, textOffset)
    firstToken.accuracy = 1
    secondToken.transcript = secondToken.transcript.slice(textOffset)
    secondToken.accuracy = 1

    secondSlice.tokenMeta.unshift(secondToken)
  } else if (type === 'token' && textOffset === 0) {
    // if the caret was at the start of the token move it to the next slice
    const firstSliceLastToken = draftTranscript.sliceMeta[sliceIndex].tokenMeta.splice(
      tokenIndex,
      1,
    )[0]
    secondSlice.tokenMeta.unshift(firstSliceLastToken)
  }

  // adjust the timestamps of the new slices

  if (!firstSlice.tokenMeta.length) {
    firstSlice.tokenMeta.push({
      transcript: ' ',
      startMs: firstSlice.startMs,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    })
  }
  const firstSliceTimings = calcSliceTimes(firstSlice)
  firstSlice.startMs = firstSliceTimings.startMs
  firstSlice.durationMs = firstSliceTimings.durationMs
  if (!secondSlice.tokenMeta.length) {
    secondSlice.tokenMeta.push({
      transcript: ' ',
      startMs: firstSlice.startMs + firstSlice.durationMs + DURATION_TOKEN_GAP_MS,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    })
  }
  const secondSliceTimings = calcSliceTimes(secondSlice)
  secondSlice.startMs = secondSliceTimings.startMs
  secondSlice.durationMs = secondSliceTimings.durationMs

  // sync the text of the new slices
  firstSlice.transcript = accumulateSliceText(firstSlice)
  secondSlice.transcript = accumulateSliceText(secondSlice)

  // hack: add a salt to slice.accuracy to use as a psuedo-unique key
  // this is necessary to prevent react key collisions, because slices don't have unique IDs
  // can get into this situation when creating multiple empty slices in a row
  firstSlice.accuracy = Number((firstSlice.accuracy || 0).toFixed(2)) + Math.random() / 100
  secondSlice.accuracy = Number((firstSlice.accuracy || 0).toFixed(2)) + Math.random() / 100

  // move the selection to the start of the second slice
  operation.afterSelection.start = {
    type: 'token',
    sliceIndex: sliceIndex + 1,
    tokenIndex: 0,
    textOffset: 0,
  }
  operation.afterSelection.end = cloneDeep(operation.afterSelection.start)
  operation.afterSelection.type = 'Caret'
}

export function insertTextRange(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: InsertAction,
): void {
  if (!operation.afterSelection?.end) {
    throw new Error('operation.afterSelection.end does not exist')
  }

  if (operation.afterSelection.type === 'Range') {
    deleteText(operation, draftTranscript)
  }

  const modifiedSliceIndices: Set<number> = new Set()
  // iterate through each character of the text to insert, carving out tokens+slices as necessary
  action.data.split('').forEach((char, i) => {
    if (!operation.afterSelection?.end) {
      throw new Error(`Could not insert character ${char} at index ${i}, no selection point`)
    }
    const {sliceIndex, tokenIndex, textOffset} = operation.afterSelection.end

    if (char === '\n') {
      // ignore repeating newlines
      if (action.data[i - 1] === '\n') return

      splitSlice(operation, draftTranscript)
      modifiedSliceIndices.add(sliceIndex)
      modifiedSliceIndices.add(sliceIndex + 1)
    } else if (
      char === ' ' &&
      textOffset > 0 &&
      draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript[textOffset - 1] !== ' '
    ) {
      // split current token
      const token = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
      const leftToken = cloneDeep(token)
      leftToken.transcript = leftToken.transcript.slice(0, textOffset) || ' '
      leftToken.accuracy = 1
      leftToken.durationMs = leftToken.transcript.length * DURATION_PER_CHAR_MS
      leftToken.aligned = false

      const rightToken = cloneDeep(token)
      rightToken.transcript = rightToken.transcript.slice(textOffset) || ' '
      rightToken.accuracy = 1
      rightToken.startMs = leftToken.startMs + leftToken.durationMs + DURATION_TOKEN_GAP_MS
      rightToken.durationMs = !rightToken.transcript.trim()
        ? 0
        : rightToken.transcript.length * DURATION_PER_CHAR_MS
      rightToken.aligned = false

      draftTranscript.sliceMeta[sliceIndex].tokenMeta.splice(tokenIndex, 1, leftToken, rightToken)

      operation.afterSelection.end.tokenIndex += 1
      operation.afterSelection.end.textOffset = 0

      modifiedSliceIndices.add(sliceIndex)
    } else {
      // add character to current token
      const token = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
      if (token.transcript === ' ' && char !== ' ') {
        token.transcript = char
        operation.afterSelection.end.textOffset = 1
      } else {
        token.transcript = `${token.transcript.slice(0, textOffset)}${char}${token.transcript.slice(
          textOffset,
        )}`
        operation.afterSelection.end.textOffset += 1
      }
      token.accuracy = 1
      token.durationMs = token.transcript.length * DURATION_PER_CHAR_MS

      modifiedSliceIndices.add(sliceIndex)
    }
  })

  modifiedSliceIndices.forEach((modifiedSliceIndex) => {
    const modifiedSlice = draftTranscript.sliceMeta[modifiedSliceIndex]
    draftTranscript.sliceMeta[modifiedSliceIndex].transcript = accumulateSliceText(
      draftTranscript.sliceMeta[modifiedSliceIndex],
    )

    const modifiedSliceTimings = calcSliceTimes(modifiedSlice)
    modifiedSlice.startMs = modifiedSliceTimings.startMs
    modifiedSlice.durationMs = modifiedSliceTimings.durationMs
  })

  // update selection
  operation.afterSelection.type = 'Caret'
  operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
}

export function insertText(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: InsertAction,
): void {
  if (!draftTranscript.sliceMeta.length) {
    draftTranscript.sliceMeta.push({
      speakerId: -1,
      speakerAccuracy: 1,
      accuracy: 1,
      startMs: 0,
      durationMs: 1,
      transcript: '',
      tokenMeta: [
        {
          transcript: ' ',
          startMs: 0,
          durationMs: 1,
          accuracy: 1,
          aligned: false,
        },
      ],
    })
    if (!draftTranscript.speakers[-1]) {
      draftTranscript.speakers[-1] = {
        name: 'Unknown speaker',
      }
    }
    if (!operation.afterSelection)
      operation.afterSelection = {type: 'Caret', start: null, end: null}

    operation.afterSelection.start = {
      type: 'token',
      sliceIndex: 0,
      tokenIndex: 0,
      textOffset: 0,
    }
    operation.afterSelection.end = {
      type: 'token',
      sliceIndex: 0,
      tokenIndex: 0,
      textOffset: 0,
    }
  }

  if (!operation.afterSelection?.end) {
    throw new Error('operation.afterSelection.end does not exist')
  }

  if (operation.afterSelection.type === 'Range') {
    deleteText(operation, draftTranscript)
  }

  if (!action.data) return

  // if selection is on a token-space pick either the left or the right token to modify
  if (operation.afterSelection.end.type === 'token-space') {
    const {sliceIndex, textOffset, tokenIndex} = operation.afterSelection.end

    if (textOffset === 0) {
      operation.afterSelection.end = {
        type: 'token',
        sliceIndex,
        tokenIndex,
        textOffset: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.length,
      }
    } else {
      const leftToken = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
      const rightToken = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex + 1]
      // create a new token if there is no token to the right
      if (!rightToken) {
        draftTranscript.sliceMeta[sliceIndex].tokenMeta.push({
          transcript: '',
          startMs: leftToken.startMs + leftToken.durationMs + DURATION_TOKEN_GAP_MS,
          durationMs: 0,
          accuracy: 1,
          aligned: false,
        })
      }

      operation.afterSelection.end = {
        type: 'token',
        sliceIndex,
        tokenIndex: tokenIndex + 1,
        textOffset: 0,
      }
    }
  }

  if (action.data.includes(' ') || action.data.includes('\n')) {
    insertTextRange(operation, draftTranscript, action)
    return
  }

  const {sliceIndex, textOffset, tokenIndex} = operation.afterSelection.end

  // insert text
  const token = draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
  if (!token.transcript || (token.transcript === ' ' && action.data !== ' ')) {
    // if the token is empty replace it with the new text
    // we keep empty tokens as spaces to ensure the elements are available inside the contenteditable
    token.transcript = action.data
  } else {
    token.transcript = `${token.transcript.slice(0, textOffset)}${
      action.data
    }${token.transcript.slice(textOffset)}`
  }

  token.accuracy = 1

  // sync the text of the slice
  draftTranscript.sliceMeta[sliceIndex].transcript = accumulateSliceText(
    draftTranscript.sliceMeta[sliceIndex],
  )

  // update selection
  operation.afterSelection.type = 'Caret'
  operation.afterSelection.end.textOffset = Math.min(
    operation.afterSelection.end.textOffset + action.data.length,
    draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.length,
  )
  operation.afterSelection.start = cloneDeep(operation.afterSelection.end)
}

export function updateSpeaker(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: UpdateSpeakerAction,
): void {
  draftTranscript.speakers[action.speakerId] = {
    ...draftTranscript.speakers[action.speakerId],
    ...action.data,
  }
}

export function addSpeaker(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: AddSpeakerAction,
): number {
  let speakerId = 0
  while (draftTranscript.speakers[speakerId]) {
    speakerId += 1
  }

  draftTranscript.speakers[speakerId] = {...action.data}

  return speakerId
}

export function deleteSpeaker(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: DeleteSpeakerAction,
): void {
  // remove speaker info
  delete draftTranscript.speakers[action.speakerId]

  // unassign speaker from slices
  draftTranscript.sliceMeta.forEach((slice) => {
    if (slice.speakerId === action.speakerId) {
      slice.speakerId = -1

      // add unknown speaker if it doesn't exist already
      if (!draftTranscript.speakers[-1]) {
        draftTranscript.speakers[-1] = {name: 'Unknown Speaker'}
      }
    }
  })
}

export function changeSliceSpeaker(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: ChangeSliceSpeakerAction,
): void {
  let {speakerId} = action
  if (speakerId === null) {
    if (!action.data) throw new Error('Speaker data must be provided when adding a new speaker')
    speakerId = addSpeaker(operation, draftTranscript, {type: 'add-speaker', data: action.data})
  }

  draftTranscript.sliceMeta[action.sliceIndex].speakerId = speakerId
  draftTranscript.sliceMeta[action.sliceIndex].speakerAccuracy = 1
}

export function updateAccuracy(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: UpdateAccuracyAction,
): void {
  const {endToken, startToken} = action
  getTokenRange(draftTranscript, {startToken, endToken}).forEach(({sliceIndex, tokenIndex}) => {
    draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].accuracy = action.accuracy
  })
}

export function alignTokens(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: AlignTokensAction,
): void {
  const modifiedSliceIndices: Set<number> = new Set()
  action.ranges.forEach(({range: {startToken, endToken}, tokens: alignedTokens}) => {
    if (!startToken || !endToken) return
    const tokenRange = getTokenRange(draftTranscript, {
      startToken: indexFromPath(startToken),
      endToken: indexFromPath(endToken),
    })

    for (let i = 0; i < alignedTokens.length; i += 1) {
      const {sliceIndex, tokenIndex} = tokenRange[i]

      // if the token is not the same as the aligned token, it means the alignment failed at this point
      // or the underlying transcript has changed, skip the rest of the tokens in this range
      if (tokenRange[i].token.transcript !== alignedTokens[i].transcript) return

      modifiedSliceIndices.add(sliceIndex)

      if (!(alignedTokens[i].startMs === 0 && alignedTokens[i].durationMs === 0)) {
        draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].startMs =
          alignedTokens[i].startMs
        draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].durationMs =
          alignedTokens[i].durationMs
      }

      draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].aligned = true
    }
  })

  // update slice timestamps
  modifiedSliceIndices.forEach((modifiedSliceIndex) => {
    const modifiedSlice = draftTranscript.sliceMeta[modifiedSliceIndex]
    const modifiedSliceTimings = calcSliceTimes(modifiedSlice)
    modifiedSlice.startMs = modifiedSliceTimings.startMs
    modifiedSlice.durationMs = modifiedSliceTimings.durationMs
  })
}

export function addAnnotation(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: AddAnnotationAction,
): string {
  if (!draftTranscript.annotations) draftTranscript.annotations = {}
  const annotationId = action.annotationId || getUniqueId()
  const annotation = {...action.annotation, id: annotationId}
  // spread here instead of direct assignment to avoid immer bug that generates
  // a patch for array assignment because the key is a number
  draftTranscript.annotations = {...draftTranscript.annotations, [annotationId]: annotation}

  action.ranges?.forEach((range) => {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = range.start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = range.end

    for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
      const slice = draftTranscript.sliceMeta[sliceIndex]

      if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
        // add annotation to all tokens in the range
        const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
        const endTokenIndexInSlice =
          sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

        for (
          let tokenIndex = startTokenIndexInSlice;
          tokenIndex <= endTokenIndexInSlice;
          tokenIndex += 1
        ) {
          const token = slice.tokenMeta[tokenIndex]
          if (!token.annotations) token.annotations = []
          if (!token.annotations.find((a) => a.id === annotationId)) {
            token.annotations.push({id: annotationId})
          }
        }
      } else {
        // add annotation to slice
        if (!slice.annotations) slice.annotations = []
        if (!slice.annotations.find((a) => a.id === annotationId)) {
          slice.annotations.push({id: annotationId})
        }
      }
    }
  })

  return annotationId
}

export function updateAnnotation(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: UpdateAnnotationAction,
): void {
  /* eslint-disable-next-line no-console */
  console.log({operation, draftTranscript, action})
}

export function deleteAnnotation(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: DeleteAnnotationAction,
): void {
  // if there is no specific range delete the top-level annotation
  if (!action.ranges) {
    delete draftTranscript.annotations?.[action.annotationId]
    return
  }

  action.ranges.forEach((range) => {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = range.start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = range.end

    for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
      const slice = draftTranscript.sliceMeta[sliceIndex]

      if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
        // remove annotation from all tokens in the range
        const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
        const endTokenIndexInSlice =
          sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

        for (
          let tokenIndex = startTokenIndexInSlice;
          tokenIndex <= endTokenIndexInSlice;
          tokenIndex += 1
        ) {
          const token = slice.tokenMeta[tokenIndex]
          if (token.annotations) {
            token.annotations = token.annotations.filter((a) => a.id !== action.annotationId)
          }
        }
      } else if (slice.annotations) {
        // remove annotation from slice
        slice.annotations = slice.annotations.filter((a) => a.id !== action.annotationId)
      }
    }
  })
}

export function replaceTokens(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: ReplaceTokensAction,
): void {
  const modifiedSliceIndices: Set<number> = new Set()
  action.data.forEach(({sliceIndex, tokenIndex, token}) => {
    // if range does not match existing tokens, skip the rest
    if (!draftTranscript.sliceMeta[sliceIndex]?.tokenMeta[tokenIndex]) return

    draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex] = token
    modifiedSliceIndices.add(sliceIndex)
  })

  modifiedSliceIndices.forEach((modifiedSliceIndex) => {
    draftTranscript.sliceMeta[modifiedSliceIndex].transcript = accumulateSliceText(
      draftTranscript.sliceMeta[modifiedSliceIndex],
    )
  })
}

export function replaceTranscript(
  operation: EditorOperation,
  draftTranscript: Draft<APITranscript>,
  action: ReplaceTranscriptAction,
): void {
  Object.keys(action.data).forEach((key) => {
    /* @ts-ignore ts(2322) */
    draftTranscript[key as keyof APITranscript] = action.data[key as keyof APITranscript]
  })

  Object.keys(draftTranscript).forEach((key) => {
    if (!(key in action.data)) delete draftTranscript[key as keyof APITranscript]
  })

  operation.afterSelection = {type: 'Caret', start: null, end: null}
}

/**
 * Produces the patches that need to be applied to perform the given edit operation on the transcript
 */
export function produceOperation({
  action,
  transcript,
  transcriptSelection,
  onError,
  log,
}: {
  action: OperationAction
  transcript: APITranscript
  transcriptSelection: TranscriptSelection | null
  onError?: (
    error: Error,
    action: OperationAction,
    transcript: APITranscript,
    transcriptSelection: TranscriptSelection | null,
  ) => void
  log: Logger
}): EditorOperation {
  // this operation is mutated by the patch operation handlers
  const operation: EditorOperation = {
    name: action.type,

    // patches and inversePatches will be populated by handlers in immer.produceWithPatches wrapper
    patches: [],
    inversePatches: [],

    // beforeSelection should never change, important for undo/redo
    beforeSelection: cloneDeep(transcriptSelection),

    // afterSelection will be updated to reflect the new selection after every handler
    // multiple handlers can be involved to produce one operation
    // handlers should always reference afterSelection
    afterSelection: cloneDeep(transcriptSelection),
  }

  try {
    // wrap all operations within a single produceWithPatches call
    // that way individual operation handlers can compose each other
    const [, patches, inversePatches] = produceWithPatches(transcript, (draftTranscript) => {
      switch (action.type) {
        case 'insert-text':
          insertText(operation, draftTranscript, action)
          break
        case 'delete-text':
          deleteText(operation, draftTranscript, action)
          break
        case 'split-slice':
          splitSlice(operation, draftTranscript)
          break
        case 'replace-tokens':
          replaceTokens(operation, draftTranscript, action)
          break
        case 'replace-transcript':
          replaceTranscript(operation, draftTranscript, action)
          break
        case 'update-speaker':
          updateSpeaker(operation, draftTranscript, action)
          break
        case 'add-speaker':
          addSpeaker(operation, draftTranscript, action)
          break
        case 'delete-speaker':
          deleteSpeaker(operation, draftTranscript, action)
          break
        case 'change-slice-speaker':
          changeSliceSpeaker(operation, draftTranscript, action)
          break
        case 'update-accuracy':
          updateAccuracy(operation, draftTranscript, action)
          break
        case 'align-tokens':
          alignTokens(operation, draftTranscript, action)
          break
        case 'add-annotation':
          addAnnotation(operation, draftTranscript, action)
          break
        case 'delete-annotation':
          deleteAnnotation(operation, draftTranscript, action)
          break
        case 'update-annotation':
          updateAnnotation(operation, draftTranscript, action)
          break
        default:
          assertNever(action, 'Unhandled operation type')
      }
    })

    operation.patches = patches
    operation.inversePatches = inversePatches
  } catch (e) {
    log.error(e as Error, {
      type: action.type,
      action: JSON.stringify(action),
    })
    if (onError) onError(e as Error, action, transcript, transcriptSelection)
  }

  return operation
}
