import {isEqual} from 'lodash-es'
import {useCallback, useEffect, useMemo, useRef} from 'react'

import getIsMac from '../utils/isMac'

/** Replace 'Mod' with 'Ctrl' or 'Meta' depending whether we are on Windows or Mac respectively */
function osSpecificKeyTransformMiddleware(key: string): string {
  if (key !== 'Mod') return key
  return getIsMac() ? 'Meta' : 'Ctrl'
}

const mappedKeys: Record<string, string> = {
  ShiftLeft: 'Shift',
  ShiftRight: 'Shift',
  AltLeft: 'Alt',
  AltRight: 'Alt',
  MetaLeft: 'Meta',
  MetaRight: 'Meta',
  OSLeft: 'Meta',
  OSRight: 'Meta',
  ControlLeft: 'Ctrl',
  ControlRight: 'Ctrl',
}

// some keys will be normalized, for example ShiftLeft and ShiftRight are both treated as the same Shift
export function mapKey(key: string): string {
  if (key in mappedKeys) return mappedKeys[key]

  return key.trim().replace(/Key|Digit|Numpad|Arrow/, '')
}

export function isModifier(key: string): boolean {
  return ['Shift', 'Alt', 'Meta', 'Ctrl'].includes(key)
}

/**
 * Add a global event listener to listen for a specific key combination
 * and invoke a callback when the combination is pressed
 *
 * @param shortcutKeys string[] of keys to listen for
 * @param callback to invoke when the shortcut is pressed
 * @param options configuration for the hook
 * @param options.preventDefault whether to prevent the default action of the keys
 * @param options.enabled whether the hook should be active
 * @param options.keydown whether to trigger the callback on keydown events, default true
 * @param options.keyup whether to trigger the callback on keyup events, default false
 * @param options.ignoreModifiers whether to ignore modifier keys when checking if the shortcut is pressed
 * @param options.enableOnContentEditable whether to enable the shortcut when the target is content editable
 */
export default function useKeyboardShortcut(
  shortcutKeys: string[],
  callback: () => void,
  options?: {
    enabled?: boolean
    keydown?: boolean
    keyup?: boolean
    preventDefault?: boolean
    ignoreModifiers?: boolean
    enableOnContentEditable?: boolean
  },
): void {
  const {
    enabled = true,
    keydown = true,
    keyup = false,
    preventDefault = true,
    ignoreModifiers = false,
    enableOnContentEditable = true,
  } = options || {}
  const pressedKeysRef = useRef<Set<string>>(new Set())
  const callbackRef = useRef<() => void>(callback)
  const targetKeys = useMemo(
    () => shortcutKeys.map((key) => osSpecificKeyTransformMiddleware(mapKey(key))).sort(),
    [shortcutKeys],
  )

  useMemo(() => {
    if (targetKeys.length < 3) return
    if (!targetKeys.includes('Meta')) return
    const nonModifierKeys = targetKeys.filter((key) => !isModifier(key))
    if (nonModifierKeys.length > 1) {
      throw new Error(
        `Invalid shortcut: ${JSON.stringify(targetKeys)}. Meta key cannot be used in combination with more than 1 non-modifier key`,
      )
    }
  }, [targetKeys])

  /** where key is a KeyboardEvent#code */
  const addPressedKey = useCallback((key: string): void => {
    const mappedKey = mapKey(key)

    // Mac does not fire keyup events for other keys while meta key is pressed
    // remove them otherwise they will be stuck in the set
    // NOTE: this means shortcuts that have meta + multiple non-modifier keys (e.g. meta + a + b)
    // will not work
    if (pressedKeysRef.current.has('Meta')) {
      pressedKeysRef.current.forEach((pressedKey) => {
        if (!isModifier(pressedKey)) pressedKeysRef.current.delete(pressedKey)
      })
    }

    pressedKeysRef.current.add(mappedKey)
  }, [])

  /** where key is a KeyboardEvent#code */
  const removePressedKey = useCallback((key: string): void => {
    const mappedKey = mapKey(key)

    if (mappedKey === 'Meta') {
      // Mac does not fire keyup events for other keys while meta key is pressed
      // remove them otherwise they will be stuck in the set
      pressedKeysRef.current.clear()
    } else {
      pressedKeysRef.current.delete(mappedKey)
    }
  }, [])

  const isShortcutPressed = useCallback((): boolean => {
    if (!ignoreModifiers && pressedKeysRef.current.size !== targetKeys.length) return false
    let pressedKeys = Array.from(pressedKeysRef.current).sort()
    if (ignoreModifiers) pressedKeys = pressedKeys.filter((key) => !isModifier(key))
    if (!isEqual(pressedKeys, targetKeys)) return false

    return true
  }, [targetKeys, ignoreModifiers])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // add event listeners to keep track of pressed keys
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      // Synthetic event (e.g., Chrome autofill), ignore
      if (event.key === undefined) return
      // Key is being held, ignore
      if (
        ['input', 'textarea', 'select'].includes(
          (event.target as HTMLElement)?.tagName?.toLowerCase(),
        )
      )
        return
      if (!enableOnContentEditable && (event.target as HTMLElement)?.isContentEditable) return

      addPressedKey(event.code)

      if (!keydown) return
      if (!isShortcutPressed()) return
      if (preventDefault) event.preventDefault()
      callbackRef.current()
    }
    const handleKeyUp = (event: KeyboardEvent): void => {
      // Synthetic event (e.g., Chrome autofill), ignore
      if (event.key === undefined) return
      // Don't listen within form fields
      if (
        ['input', 'textarea', 'select'].includes(
          (event.target as HTMLElement).tagName?.toLowerCase(),
        )
      )
        return
      if (!enableOnContentEditable && (event.target as HTMLElement)?.isContentEditable) return

      removePressedKey(event.code)

      if (!keyup) return
      // check if the pressed keys match the target keys, and if so invoke the callback
      if (!isShortcutPressed()) return
      if (preventDefault) event.preventDefault()
      callbackRef.current()
    }

    const clearPressedKeys = (): void => {
      pressedKeysRef.current.clear()
    }

    if (enabled) {
      window.addEventListener('focus', clearPressedKeys)
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyUp)
    }

    return (): void => {
      window.removeEventListener('focus', clearPressedKeys)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [
    enabled,
    keydown,
    keyup,
    preventDefault,
    enableOnContentEditable,
    isShortcutPressed,
    addPressedKey,
    removePressedKey,
  ])
}
