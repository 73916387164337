import {useEffect, useState, MutableRefObject} from 'react'
import {useDevicePixelRatio} from '@kensho/tacklebox'

import {AnnotationsLayerBlock} from './AnnotationsLayerBlock'
import {KHighlight, StyledSegments} from './types'

export function AnnotationsLayer(props: {
  height: number
  width: number
  visibleBatches: Record<string, boolean>
  transcriptRef: MutableRefObject<HTMLDivElement | null>
  kHighlights: KHighlight[]
}): React.ReactNode {
  const {height, width, kHighlights} = props
  const [styledSegments, setStyledSegments] = useState<StyledSegments[] | undefined>()
  const pixelRatio = useDevicePixelRatio()

  useEffect(() => {
    const nextStyledSegments: StyledSegments[] = []
    kHighlights.forEach((kHighlight) => {
      kHighlight.ranges.forEach((range) => {
        if (range.segments) {
          nextStyledSegments.push({segmentGroup: range.segments, style: kHighlight.style})
        }
      })
    })
    setStyledSegments(nextStyledSegments)
  }, [kHighlights])

  const blocks = []
  const CANVAS_BLOCK_HEIGHT = 512
  for (let i = 0; i < height; i += CANVAS_BLOCK_HEIGHT) {
    const remainingPixels = height - i
    blocks.push(
      <AnnotationsLayerBlock
        key={i}
        styledSegments={styledSegments || []}
        height={Math.min(CANVAS_BLOCK_HEIGHT, remainingPixels)}
        pixelRatio={pixelRatio}
        width={width}
        x={0}
        y={i}
      />,
    )
  }
  return blocks
}
